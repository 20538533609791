class SegmentEventBus extends EventTarget {
  private listeners = new Map<string, Map<(event: CustomEvent) => void, EventListener>>();

  public emit<T>(eventName: string, detail: T): void {
    this.dispatchEvent(new CustomEvent(eventName, { detail }));
  }

  public on<T>(eventName: string, callback: (event: CustomEvent<T>) => void): void {
    const wrappedCallback: EventListener = (event) => {
      if (event instanceof CustomEvent) {
        callback(event);
      }
    };

    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, new Map());
    }

    this.listeners.get(eventName)?.set(callback, wrappedCallback);

    this.addEventListener(eventName, wrappedCallback);
  }

  public off<T>(eventName: string, callback: (event: CustomEvent<T>) => void): void {
    const eventListeners = this.listeners.get(eventName);
    if (!eventListeners) return;

    const wrappedCallback = eventListeners.get(callback);
    if (wrappedCallback) {
      this.removeEventListener(eventName, wrappedCallback);
      eventListeners.delete(callback);

      if (eventListeners.size === 0) {
        this.listeners.delete(eventName);
      }
    }
  }
}

export const segmentEventBus = new SegmentEventBus();
