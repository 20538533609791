import { useDebouncedValue } from '@mantine/hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import type { GridColDef } from '@mui/x-data-grid';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { formatSecondsToHHMMSS } from 'utils/time';
import { isDefined } from 'utils/ts/is-defined';
import { maxLinesFoldedArr, StripedDataGrid, type TaskSchedulerInfo } from './scheduler/task-and-robots-scheduler-info';
import { taskStateNumberToStr } from './states';

interface TaskTableProps {
  tasksArr: TaskSchedulerInfo[];
}

const allAvailableTasksStatus = Array.from(
  new Set(
    new Array(100)
      .fill(0)
      .map((_, i) => i - 10)
      .map((i) => taskStateNumberToStr(i))
  )
);

export default function TaskTable({ tasksArr }: TaskTableProps): JSX.Element {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 75, type: 'number' },
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: allAvailableTasksStatus },
    { field: 'taskName', headerName: 'Task Name', type: 'string' },
    { field: 'stepLabel', headerName: 'Step', type: 'string' },
    { field: 'source', headerName: 'Source' },
    { field: 'destination', headerName: 'Destination' },
    { field: 'duration', headerName: 'Duration', type: 'string' },
    { field: 'robotId', headerName: 'Robot' },
    { field: 'robotAuthorised', headerName: 'Authorized Robots' },
    { field: 'waitingTime', headerName: 'Waiting Time', type: 'string' },
    { field: 'priority', headerName: 'Priority', type: 'number' },
    { field: 'dueDate', headerName: 'Due Date', type: 'string' },
  ];

  return (
    <StripedDataGrid
      columns={columns}
      rows={tasksArr}
      disableRowSelectionOnClick
      getCellClassName={(params) => {
        if (params.field === 'status' && params.value) return `task-status ${params.value.toString()}`;

        return '';
      }}
      sx={{ maxHeight: 'inherit' }}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
    ></StripedDataGrid>
  );
}

interface ListOfTasksMenuProps {
  foldedTasks: boolean;
  setFoldedTasks: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ListOfTasksMenu = ({ foldedTasks, setFoldedTasks }: ListOfTasksMenuProps): JSX.Element => {
  const tasks = useAppSelector((state) => state.simulation.tasks);
  const robots = useAppSelector((state) => state.robots.robots);
  const flows = useAppSelector((state) => state.flows.flows);

  const simulationTime = useAppSelector((state) => state.simulation.simulatedTime);
  const simulationStartEpoch = useAppSelector((state) => state.simulation.startEpochTime);

  const collapseTime = theme.transitions.duration.short;

  /**
   * When the task is done after this duration, it will be skipped from the list of tasks
   * [s]
   */
  const durationSkipDoneTasks = 300; // 5 minutes
  const durationSkipCanceledTasks = 3600; // 1 hr

  const tasksArr: TaskSchedulerInfo[] = useMemo(
    () =>
      Object.entries(tasks)
        .map(([taskId, task]) => {
          const nowInTasksFrameOfReference = simulationStartEpoch + simulationTime;

          const status = taskStateNumberToStr(task.state);

          if (status === 'done' && task.endDate !== undefined) {
            const doneFor = nowInTasksFrameOfReference - task.endDate;
            if (doneFor > durationSkipDoneTasks) {
              return undefined;
            }
          } else if (status === 'canceled' && task.creationDate !== undefined) {
            const canceledFor = nowInTasksFrameOfReference - task.creationDate;
            if (canceledFor > durationSkipCanceledTasks) {
              return undefined;
            }
          }

          let duration: number | undefined = undefined;
          if (task.startDate !== undefined && task.endDate !== undefined) {
            duration =
              task.startDate === 0
                ? 0
                : task.startDate !== 0 && task.endDate !== 0
                  ? task.endDate - task.startDate
                  : nowInTasksFrameOfReference - task.startDate;
          }

          let waitingTime = 0;
          if (task.creationDate !== undefined && task.startDate !== undefined) {
            waitingTime =
              task.startDate === 0
                ? nowInTasksFrameOfReference - task.creationDate
                : task.startDate - task.creationDate;
          }

          const robot = robots.find((robot) => robot.id === task.robotID);
          const robotName = robot?.name;

          const flow = flows.find((flow) => flow.name === task.taskName);

          const robotsAuthorisedName = task.robotAuthorised
            ?.map((id) => {
              const robAuthorized = robots.find((robot) => robot.id === id);
              const robotAuthorizedSerial = robAuthorized?.serial;

              if (
                flow &&
                flow.robotsAssigned !== 'all' &&
                (!robotAuthorizedSerial || !flow.robotsAssigned?.includes(robotAuthorizedSerial))
              ) {
                return null;
              }

              const robotName = robots.find((robot) => robot.id === id)?.name;

              return robotName;
            })
            .filter(isDefined);

          const source = task.startPoint ?? (task.src ? positionIdToPositionName(task.src.toString(), 'all') : '');
          const destination =
            task.destinationPoint ?? (task.dst ? positionIdToPositionName(task.dst.toString(), 'all') : '');

          const dueDate = task.dueDate;

          return {
            id: task.id,
            taskName: task.taskName,
            source: source,
            destination: destination,
            duration: duration && duration > 0 ? formatSecondsToHHMMSS(duration) : '',
            robotId: robotName ?? '',
            robotAuthorised: robotsAuthorisedName ? robotsAuthorisedName.join(', ') : '',
            waitingTime: waitingTime > 0 ? formatSecondsToHHMMSS(waitingTime) : '',
            priority: task.priority,
            dueDate: dueDate && dueDate > 0 ? formatSecondsToHHMMSS(dueDate) : '',
            status,
            stepLabel: task.stepLabel !== 'Init' ? task.stepLabel ?? '' : '',
          };
        })
        .filter(isDefined),
    [flows, robots, simulationStartEpoch, simulationTime, tasks]
  );

  const [showTasksList, setShowTasksList] = useState(false);
  const [showTasksListDebounced] = useDebouncedValue(showTasksList, collapseTime);

  const handleClickShowTasksList = useCallback((): void => {
    setShowTasksList(!showTasksList);
  }, [showTasksList]);

  useEffect(() => {
    if (!showTasksList && !foldedTasks) {
      setFoldedTasks(!foldedTasks);
    }
  }, [foldedTasks, setFoldedTasks, showTasksList]);

  return (
    <div style={{ pointerEvents: 'none', marginTop: theme.spacing(1) }}>
      <Card
        sx={{
          pointerEvents: 'initial',
          margin: 0,
        }}
      >
        <CardHeader
          sx={{
            userSelect: 'none',
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            marginTop: 0,
          }}
          avatar={<FormatListBulletedIcon></FormatListBulletedIcon>}
          title="List of waiting and ongoing tasks"
          action={
            <>
              {Object.keys(tasks).length > maxLinesFoldedArr && showTasksList && (
                <IconButton
                  title={foldedTasks ? 'Expand' : 'Fold'}
                  onClick={() => setFoldedTasks(!foldedTasks)}
                  size="large"
                >
                  {foldedTasks ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                </IconButton>
              )}

              <IconButton
                title={showTasksList ? 'Hide' : 'Show'}
                aria-label="Show layers"
                onClick={handleClickShowTasksList}
                size="large"
              >
                {!showTasksList ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
              </IconButton>
            </>
          }
        ></CardHeader>
        <Collapse in={showTasksList} timeout={collapseTime}>
          <CardContent
            sx={{
              padding: theme.spacing(1),
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: `${theme.spacing(1)} !important`,
              textAlign: 'left',
            }}
          >
            <Box
              component="div"
              sx={{ padding: 0, maxHeight: foldedTasks ? '250px' : '76vh', maxWidth: '60vw', width: '60vw' }}
            >
              {showTasksList || showTasksListDebounced ? <TaskTable tasksArr={tasksArr}></TaskTable> : null}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
