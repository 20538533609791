import type { CircuitRack, CircuitStockZone } from 'models/circuit';
import store from 'store';

interface NbLoadsInRackResult {
  /** The select load of the selected template only */
  selected: number;
  /** The load of the cell template with the maximum number (greater load.N), we consider that the diffetent loads do not superimpose */
  max: number;
  /** The max number of loads minus the number of desactivated loads */
  maxMinusDeactivedLoads: number;
}

/**
 * Compute the number of loads in a rack
 * @param rack the rack to count in
 * @returns An object with different counts of the loads in the rack
 */
export function computeNbLoadsInRack(rack: CircuitRack): NbLoadsInRackResult {
  const cellTemplates = store.getState().circuit.present.cellTemplates;

  const res: NbLoadsInRackResult = {
    selected: 0,
    max: 0,
    maxMinusDeactivedLoads: 0,
  };

  let nbOfDeactivatedLoads = 0;

  rack.properties.columns.forEach((column) => {
    column.cells.forEach((cell) => {
      if (!cell.cellTemplate) return;
      const cellTemplate = cellTemplates.entities[cell.cellTemplate];
      if (!cellTemplate) return;

      if (cell.disabled) return; // disabled cells are not counted

      const loads = cellTemplate.loads;

      cell.names.forEach((load) => {
        load.forEach((pallet) => {
          if (pallet.disabled) {
            nbOfDeactivatedLoads += 1;
          }
        });
      });

      const nbSelectedLoad = loads[cellTemplate.selectedLoad].N;
      const nbMax = loads.reduce((acc, load) => (load.N > acc ? load.N : acc), 0);

      res.selected += nbSelectedLoad;
      res.max += nbMax;
    });
  });

  res.maxMinusDeactivedLoads = res.max - nbOfDeactivatedLoads;

  return res;
}

/**
 * Compute the number of loads in a stock zone
 * @param stockZoneOrSlots the stockzone to count in or the slotlines of the stockzone
 * @returns the number of loads in the stock zone
 */
export function computeNbLoadsInStockZone(
  stockZoneOrSlots: CircuitStockZone | CircuitStockZone['properties']['slots']
): number {
  let res = 0;

  const slotLines = Array.isArray(stockZoneOrSlots) ? stockZoneOrSlots : stockZoneOrSlots.properties.slots;

  slotLines.forEach((slotLine) => {
    slotLine.slots.forEach((slot) => {
      if (slot.disabled) return; // disabled slots are not counted

      res += 1;
    });
  });

  return res;
}
