import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { CircuitStockZone } from 'models/circuit';
import type { BalyoSimulationPromptMsg, RobotTimers } from 'models/simulation';
import type { ErrorMonitoringAction } from 'services/simu-errors-monitoring-action';
import { simulationLoadingStep } from 'services/simulation.service';
import { latestBalyoSimulationVersion } from './BalyoSimulation/available-balyo-simulation-versions';
import { displayPromptSnackbar } from './display-prompt';
import { checkMessageNeedsSnackbar } from './group-simulation-messages';
import type { PerfoRecordData } from './perfo-data';

// see https://github.com/reduxjs/redux-toolkit/issues/1806#issuecomment-1536690651
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/imports-first

export enum PresetSimulationEnum {
  Demonstration,
  Detailed,
  Throughput,
  Custom,
}

export interface SimulationTask {
  /** the id of the task, the ID used by the simulator/robot manager */
  id: number | string;
  /** unique ID for the task */
  uniqueId: string;
  /** state of the task, the SDK value */
  state: number;
  /** the name of the task */
  taskName: string;
  /** the robot which is doing (or which did) the task */
  robotID?: number;
  /** the time at which the task was created */
  creationDate?: number;
  /** the time at which the task was started */
  startDate?: number;
  /** the time at which the task was finished */
  endDate?: number;
  /** the time at which the steps was finished */
  stepEndDate?: number[];
  /**
   * names of the steps
   * example: ["pick", "drop", "move", "battery", "batteryWaiting"]
   **/
  stepNames?: string[];
  /** Label of the current step */
  stepLabel?: string;

  /**
   * All the performance records saved for this task
   */
  records?: Record<string, PerfoRecordData>;
  /** id of the source point */
  src?: number;
  /** name of the source point */
  startPoint?: string;
  /** id of the destination point */
  dst?: number;
  /** name of the destination point */
  destinationPoint?: string;
  /** list of robots allowed to do task */
  robotAuthorised?: number[] | [string | number][];
  /** priority of task [1] */
  priority?: number;
  /** due date of the task [s] */
  dueDate?: number;
}

export type RobotBattery = {
  /** id of the robot */
  id: string;
  /** battery level of the robot [0-100] */
  batteryLevel: number;
};

/**
 * StockManagerRecord saves the stock of each stock line
 * key = stock line ID
 * value = number of pallets in the stock line
 */
export type StockManagerRecord = Record<string, number | undefined>;

export type RobotsTimersHistory = {
  simulationTime: number;
  robotsTimers: { robId: number; timers: RobotTimers }[];
};

export type RobotHistoryReset = {
  robotName: string;
  errorType: 'deadlock' | 'robotError';
  position: {
    x: number;
    y: number;
    heading: number;
  };
  simulationTime: number;
};

export interface SimulationSliceState {
  /** whether the simulation has been loaded once */
  loadedOnce: boolean;
  /** the target speed we want the simulation to run at (ex: 1 = x1, 2 = x2, 100 = x100) [1] */
  speedFactor: number;
  /** the actual speed the simulation ran during the last check [1] */
  currentSpeed: number;
  /** the mean speed of the simulation since the beggining of the simulation [1] */
  meanSpeed: number;
  /** number of seconds elapsed in the simulation thread since the beggining of the simulation [s] */
  simulatedTime: number;
  /** the time at which the simulation started [s] */
  startEpochTime: number;

  /** max duration of the simulation, 0 = infinite [s] */
  maxDuration: number;

  /** the list of tasks in the simulation */
  tasks: Record<string, SimulationTask>;

  /** whether or not to enable logs in the simulation */
  enableLogs: boolean;

  /** the opacity of the robots in the simulation */
  robotsOpacity: number;

  /** whether or not we display/refresh the simulation data (robots, itineraries, etc.) */
  enableDisplay: boolean;

  /** whether the robots lose battery level and go to charge */
  enableCharging: boolean;

  /** whether or not to enable fast refresh (fast refresh = we refresh as many times the UI of the simulation instead of 1 Hz) */
  enableFastRefresh: boolean;

  /** whether we display the robots display data in the UI during the simulation (= the itineraries as well as some cantons) */
  enableDisplayTraffic: boolean;

  /** whether we display the robots display data in the UI during the simulation (= all cantons) */
  enableAdvancedDisplayTraffic: boolean;

  /** whether or not to enable the libPerfo (performance library) */
  enableLibPerfo: boolean;

  /** the name of the scheduler configuration used */
  schedulerConfiguration: 'standard' | string | 'custom';

  /** array of battery level for each robot present on the circuit */
  robotsBatteryLevel: RobotBattery[];

  /** whether or not to display all the robots data (robots tooltips) */
  displayAllRobotsData: boolean;

  /** The stock (= number of pallets) of the stock lines */
  stock: StockManagerRecord;

  /** the time at which the data of the simulation will be collected (in sec)*/
  startCollectingTime: number;
  /** the robots timers data collected every "simulation time" interval (5min)*/
  robotsTimersHistory: RobotsTimersHistory[];

  /** Ids of the deadlock robots */
  deadLockRobots: number[];

  /** Data about the loading status of the simulation */
  loading?: {
    /** The main title of the s */
    title: string;
    /** The module name that is currently being loaded */
    brief: string;
    /** The percentage of loading of the module that is currently being loaded [0-1] */
    percent: number | undefined;
    /** The current step number of the loading */
    stepNb: number;
  };

  /** Actions to perform when the simulation encounters an error */
  errorsMonitoring: {
    robotError: ErrorMonitoringAction;
    deadlock: ErrorMonitoringAction;
  };
  /** Has the simulation started */
  hasSimulationStarted: boolean;
  /** is the simulation paused */
  isSimulationPaused: boolean;
  /** is the simulation running */
  isSimulationRunning: boolean;
  /** the preconfiguration that we want for the simulator (Demonstration/Detailed/Throughput/Custom)*/
  presetSimulation: PresetSimulationEnum;
  /**Array of all the robots automatically reset with the errors monitoring parametter set to "reset robots*/
  robotsHistoryReset: RobotHistoryReset[];
  /** BalyoSimulation version to use */
  balyoSimulationVersion: string;
  /** Ids of the robots for which we want to display the data in the simulation */
  pinnedRobots: number[];
}

const initialState: SimulationSliceState = {
  loadedOnce: false,
  speedFactor: 1,
  currentSpeed: 1,
  meanSpeed: 1,
  simulatedTime: 0,
  startEpochTime: 0,
  maxDuration: 0,
  tasks: {},
  enableLogs: true,
  robotsOpacity: 1,
  enableDisplay: true,
  enableCharging: true,
  enableFastRefresh: false,
  enableDisplayTraffic: true,
  enableAdvancedDisplayTraffic: false,
  enableLibPerfo: true,
  displayAllRobotsData: false,
  schedulerConfiguration: 'standard',
  robotsBatteryLevel: [],
  stock: {},
  startCollectingTime: 0,
  robotsTimersHistory: [],
  deadLockRobots: [],
  errorsMonitoring: {
    robotError: 'StopSimu',
    deadlock: 'StopSimu',
  },
  hasSimulationStarted: false,
  isSimulationPaused: false,
  isSimulationRunning: false,
  presetSimulation: PresetSimulationEnum.Demonstration,
  robotsHistoryReset: [],
  balyoSimulationVersion: latestBalyoSimulationVersion.version,
  pinnedRobots: [],
};

const simulationSlicePrivate = createSlice({
  initialState,
  name: 'simulation',
  reducers: {
    setSimulationLoadedOnce: (state) => {
      state.loadedOnce = true;
    },
    setSpeedFactor: (state, action: PayloadAction<number>) => {
      state.speedFactor = action.payload;

      // we automatically change the display traffic parameters depending on the speed factor
      state.enableDisplayTraffic = state.speedFactor <= 1;
    },
    setCurrentSpeed: (state, action: PayloadAction<number>) => {
      state.currentSpeed = action.payload;
    },
    setSimulatedTime: (state, action: PayloadAction<number>) => {
      state.simulatedTime = action.payload;
    },
    setStartEpochTime: (state, action: PayloadAction<number>) => {
      state.startEpochTime = action.payload;
    },
    setSimulatedTimeAndCurrentSpeed: (
      state,
      action: PayloadAction<{ simulatedTime: number; currentSpeed: number; meanSpeed?: number }>
    ) => {
      state.simulatedTime = action.payload.simulatedTime;
      state.currentSpeed = action.payload.currentSpeed;
      if (action.payload.meanSpeed !== undefined) state.meanSpeed = action.payload.meanSpeed;
    },
    resetSimulation: (state, action: PayloadAction<void>) => {
      const newState = structuredClone(initialState);

      /**
       * All the parameters we do not want to reset
       */
      newState.schedulerConfiguration = state.schedulerConfiguration;
      newState.maxDuration = state.maxDuration;
      newState.speedFactor = state.speedFactor;
      newState.robotsBatteryLevel = state.robotsBatteryLevel;
      newState.enableCharging = state.enableCharging;
      newState.balyoSimulationVersion = state.balyoSimulationVersion;
      newState.presetSimulation = state.presetSimulation;
      newState.robotsOpacity = state.robotsOpacity;
      newState.enableLibPerfo = state.enableLibPerfo;

      if (newState.presetSimulation === PresetSimulationEnum.Custom) {
        newState.enableDisplay = state.enableDisplay;
        newState.enableFastRefresh = state.enableFastRefresh;
        newState.displayAllRobotsData = state.displayAllRobotsData;
        newState.enableDisplayTraffic = state.enableDisplayTraffic;
        newState.enableAdvancedDisplayTraffic = state.enableAdvancedDisplayTraffic;
        newState.errorsMonitoring = state.errorsMonitoring;
      }

      return newState;
    },
    setMaxDuration: (state, action: PayloadAction<number>) => {
      state.maxDuration = action.payload;
    },
    setTask: (state, action: PayloadAction<SimulationTask>) => {
      state.tasks[action.payload.uniqueId] = action.payload;
    },
    addPerfoRecordToTask: (state, action: PayloadAction<{ record: PerfoRecordData }>) => {
      const taskID = action.payload.record.taskID;
      const task = state.tasks[taskID];
      if (!task) {
        return;
      }

      if (task.records === undefined) {
        task.records = {};
      }

      task.records[action.payload.record.recordID] = action.payload.record;
    },
    addSeveralPerfoRecordsToTasks: (state, action: PayloadAction<{ records: PerfoRecordData[] }>) => {
      const records = action.payload.records;
      records.forEach((record) => {
        const task = state.tasks[record.taskID];
        if (!task) {
          return;
        }

        if (task.records === undefined) {
          task.records = {};
        }

        task.records[record.recordID] = record;
      });
    },
    setMultipleTasks: (state, action: PayloadAction<Record<string, SimulationTask>>) => {
      state.tasks = { ...state.tasks, ...action.payload };
    },
    setEnableLogs: (state, action: PayloadAction<boolean>) => {
      state.enableLogs = action.payload;
    },
    setEnableCharging: (state, action: PayloadAction<boolean>) => {
      state.enableCharging = action.payload;
    },

    displayPrompt: (state, action: PayloadAction<BalyoSimulationPromptMsg>) => {
      const displaySnackBar = checkMessageNeedsSnackbar(action.payload, state.loadedOnce);

      if (displaySnackBar) {
        displayPromptSnackbar(action.payload);

        const deadLockRobots = state.deadLockRobots;

        if (action.payload.category === 'Robot-Deadlock') {
          if (action.payload.parameter) {
            deadLockRobots.push(action.payload.parameter);
          }

          state.deadLockRobots = deadLockRobots;
        }
      }

      return state;
    },
    resetDeadLockRobots: (state, action: PayloadAction<void>) => {
      state.deadLockRobots = [];
    },
    setRobotsOpacity: (state, action: PayloadAction<number>) => {
      state.robotsOpacity = action.payload;
    },
    setSimulationDisplayState: (state, action: PayloadAction<boolean>) => {
      state.enableDisplay = action.payload;
    },
    setEnableFastRefresh: (state, action: PayloadAction<boolean>) => {
      state.enableFastRefresh = action.payload;
    },
    setEnableDisplayTraffic: (state, action: PayloadAction<boolean>) => {
      state.enableDisplayTraffic = action.payload;
    },
    setEnableAdvancedDisplayTraffic: (state, action: PayloadAction<boolean>) => {
      state.enableAdvancedDisplayTraffic = action.payload;
    },
    setEnableLibPerfo: (state, action: PayloadAction<boolean>) => {
      state.enableLibPerfo = action.payload;
    },
    setSchedulerConfiguration: (state, action: PayloadAction<'standard' | string | 'custom'>) => {
      state.schedulerConfiguration = action.payload;
    },

    setRobotsInitialBatteryLevel: (state, action: PayloadAction<{ robots: RobotBattery[] }>) => {
      const robotsInitBatteryLevel = action.payload.robots;

      state.robotsBatteryLevel = [...state.robotsBatteryLevel, ...robotsInitBatteryLevel];

      // remove duplicate, only keep the last one for every robot
      const uniqueRobots = state.robotsBatteryLevel.reduceRight(
        (acc, robot) => {
          const robotExists = acc.find((r) => r.id === robot.id);
          if (!robotExists) {
            acc.push(robot);
          }

          return acc;
        },
        [] as typeof state.robotsBatteryLevel
      );

      // sort by robot ID
      uniqueRobots.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

      state.robotsBatteryLevel = uniqueRobots;
    },
    resetRobotsInitialBatteryLevel: (state, action: PayloadAction<void>) => {
      state.robotsBatteryLevel = [];
    },
    setDisplayAllRobotsData: (state, action: PayloadAction<boolean>) => {
      state.displayAllRobotsData = action.payload;
    },
    setStockStockLine: (state, action: PayloadAction<{ stockLineId: string; stock: number }>) => {
      state.stock[action.payload.stockLineId] = action.payload.stock;
    },
    decreaseStockLineStock: (
      state,
      action: PayloadAction<{
        stockLineId: string;
        stockLine: CircuitStockZone['properties']['slots'][0];
        quantity?: number;
      }>
    ) => {
      const quantity = action.payload.quantity || 1;
      let newStock = (state.stock[action.payload.stockLineId] || 0) - quantity;
      const stockLine = action.payload.stockLine;
      const maxStock = stockLine.slots.length || 1;
      if (newStock < 0) newStock = maxStock;

      state.stock[action.payload.stockLineId] = newStock;
    },
    increaseStockLineStock: (
      state,
      action: PayloadAction<{
        stockLineId: string;
        stockLine: CircuitStockZone['properties']['slots'][0];
        quantity?: number;
      }>
    ) => {
      const quantity = action.payload.quantity || 1;
      let newStock = (state.stock[action.payload.stockLineId] || 0) + quantity;
      const stockLine = action.payload.stockLine;

      const maxStock = stockLine.slots.length || 1;
      if (newStock > maxStock) newStock = 0;

      state.stock[action.payload.stockLineId] = newStock;
    },
    setStartCollectingTime: (state, action: PayloadAction<number>) => {
      state.startCollectingTime = action.payload;
    },
    addRobotsTimersToHistory: (
      state,
      action: PayloadAction<{
        robotTimersHistory: RobotsTimersHistory;
      }>
    ) => {
      const newCollectedData = action.payload.robotTimersHistory;
      state.robotsTimersHistory.push(newCollectedData);
    },
    setLoadingState: (state, action: PayloadAction<SimulationSliceState['loading']>) => {
      state.loading = action.payload;
    },
    resetLoadingState: (state) => {
      state.loading = undefined;

      simulationLoadingStep.lastStepBrief = '';
      simulationLoadingStep.stepNb = 0;
    },
    setErrorsMonitoringMode: (
      state,
      action: PayloadAction<{
        newMode: keyof SimulationSliceState['errorsMonitoring'];
        newValue: SimulationSliceState['errorsMonitoring']['deadlock'];
      }>
    ) => {
      state.errorsMonitoring[action.payload.newMode] = action.payload.newValue;
    },
    setAllErrorsMonitoringMode: (
      state,
      action: PayloadAction<{
        newValue: SimulationSliceState['errorsMonitoring']['deadlock'];
      }>
    ) => {
      const newErrorMonitoring = {
        robotError: action.payload.newValue,
        deadlock: action.payload.newValue,
      };
      state.errorsMonitoring = newErrorMonitoring;
    },
    setHasSimulationStarted: (state, action: PayloadAction<boolean>) => {
      state.hasSimulationStarted = action.payload;
    },
    setIsSimulationPaused(state, action: PayloadAction<boolean>) {
      state.isSimulationPaused = action.payload;
    },
    setIsSimulationRunning(state, action: PayloadAction<boolean>) {
      state.isSimulationRunning = action.payload;
    },
    setPresetSimulation: (state, action: PayloadAction<number>) => {
      state.presetSimulation = action.payload;
    },
    setRobotHistoryReset: (
      state,
      action: PayloadAction<{
        robotHistoryReset: RobotHistoryReset;
      }>
    ) => {
      const newRobotHistoryReset = action.payload.robotHistoryReset;

      state.robotsHistoryReset.push(newRobotHistoryReset);
    },
    setBalyoSimulationVersion: (state, action: PayloadAction<string>) => {
      state.balyoSimulationVersion = action.payload;
    },
    setPinnedRobots: (state, action: PayloadAction<number>) => {
      const robotId = action.payload;
      let pinnedRobots = state.pinnedRobots;

      if (pinnedRobots.includes(robotId)) {
        pinnedRobots = pinnedRobots.filter((id) => id !== robotId);
      } else {
        pinnedRobots.push(robotId);
      }

      state.pinnedRobots = pinnedRobots;
    },
  },
});

/**
 * @link https://github.com/reduxjs/redux-toolkit/issues/1806#issuecomment-1895702610
 */
export const simulationSlice = {
  reducer: simulationSlicePrivate.reducer,
  name: simulationSlicePrivate.name,
};

export const {
  setSpeedFactor,
  setCurrentSpeed,
  setSimulatedTime,
  setSimulatedTimeAndCurrentSpeed,
  resetSimulation,
  setMaxDuration,
  setTask,
  setMultipleTasks,
  setEnableLogs,
  displayPrompt,
  resetDeadLockRobots,
  setRobotsOpacity,
  setEnableCharging,
  setSimulationDisplayState,
  setEnableFastRefresh,
  setEnableDisplayTraffic,
  setEnableLibPerfo,
  addPerfoRecordToTask,
  addSeveralPerfoRecordsToTasks,
  setSchedulerConfiguration,
  setRobotsInitialBatteryLevel,
  resetRobotsInitialBatteryLevel,
  setDisplayAllRobotsData,
  setStockStockLine,
  decreaseStockLineStock,
  increaseStockLineStock,
  setStartEpochTime,
  setStartCollectingTime,
  addRobotsTimersToHistory,
  setEnableAdvancedDisplayTraffic,
  setSimulationLoadedOnce,
  setLoadingState,
  resetLoadingState,
  setErrorsMonitoringMode,
  setAllErrorsMonitoringMode,
  setHasSimulationStarted,
  setIsSimulationPaused,
  setIsSimulationRunning,
  setPresetSimulation,
  setRobotHistoryReset,
  setBalyoSimulationVersion,
  setPinnedRobots,
} = simulationSlicePrivate.actions;
