import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch } from '@mui/material';
import { setRender2Mode } from 'editor/editor';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

export function RenderingEngine(): JSX.Element {
  const dispatch = useAppDispatch();

  const isRender2Enabled = useAppSelector((state) => state.editor.isRender2Enabled);

  const handleChangeLibCir = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        delete localStorage.drawnBackgroundLidar;
      }

      dispatch(
        setRender2Mode({
          enableRender2: checked,
        })
      );
    },
    [dispatch]
  );

  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true} sx={{ marginTop: theme.spacing(1) }}>
      <FormLabel>Rendering engine</FormLabel>

      {
        <>
          <FormControlLabel
            labelPlacement="start"
            control={<Switch checked={isRender2Enabled} onChange={handleChangeLibCir} name="Render 2" />}
            label="Render 2"
            sx={{
              flexDirection: 'row',
              marginTop: theme.spacing(1),
            }}
          ></FormControlLabel>

          <FormHelperText>
            Render 2 is a rewrite of our rendering engine with a 3D engine. It offers better performance but is still in
            beta. Consider using it for large projects but it might lack a few features and might have some bugs.
            <br />
            <br />
            This is the default mode. You can disable it to use the old rendering engine.
          </FormHelperText>
        </>
      }
    </FormControl>
  );
}
