import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Card, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'utils/mui-theme';
import { ListOfTasksMenu } from './listOfTasksMenu';
import { RobotsSummaryView } from './robotsSummaryView';

export const SimulationTablesView = (): JSX.Element => {
  const [showRobotsSummaryView, setRobotsSummaryView] = useState(false);
  const [showTasksList, setShowTasksList] = useState(false);

  const handleClickShowMenu = useCallback(
    (menu: 'robotView' | 'tasksList'): void => {
      if (menu === 'robotView') {
        setRobotsSummaryView(!showRobotsSummaryView);
      }

      if (menu === 'tasksList') {
        setShowTasksList(!showTasksList);
      }
    },
    [showRobotsSummaryView, showTasksList]
  );

  const [foldedTasks, setFoldedTasks] = useState(true);
  const [foldedRobots, setFoldedRobots] = useState(true);

  useEffect(() => {
    if (!foldedTasks && showRobotsSummaryView) {
      setRobotsSummaryView(!showRobotsSummaryView);
    }

    if (!foldedRobots && showTasksList) {
      setShowTasksList(!showTasksList);
    }
  }, [foldedRobots, foldedTasks, showRobotsSummaryView, showTasksList]);

  return (
    <div
      style={{
        pointerEvents: 'none',
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <div
        style={{
          marginRight: theme.spacing(1),
        }}
      >
        <Card
          sx={{
            pointerEvents: 'initial',
            borderRadius: 50,
          }}
        >
          <IconButton
            title={showRobotsSummaryView ? 'Hide robots summary view' : 'Show robots summary view'}
            onClick={() => handleClickShowMenu('robotView')}
            size="large"
            color={showRobotsSummaryView ? 'primary' : 'inherit'}
          >
            <SmartToyIcon></SmartToyIcon>
          </IconButton>
        </Card>

        <Card
          sx={{
            pointerEvents: 'initial',
            borderRadius: 50,
            marginTop: theme.spacing(1),
          }}
        >
          <IconButton
            title={showTasksList ? 'Hide list of taks scheduler' : 'Show list of taks scheduler'}
            onClick={() => handleClickShowMenu('tasksList')}
            size="large"
            color={showTasksList ? 'primary' : 'inherit'}
          >
            <FormatListBulletedIcon></FormatListBulletedIcon>
          </IconButton>
        </Card>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showRobotsSummaryView && <RobotsSummaryView foldedRobots={foldedRobots} setFoldedRobots={setFoldedRobots} />}
        {showTasksList && <ListOfTasksMenu foldedTasks={foldedTasks} setFoldedTasks={setFoldedTasks} />}
      </div>
    </div>
  );
};
