import { Visibility } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Backdrop,
  CircularProgress,
  Fade,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { grey } from '@mui/material/colors';
import { TrucksChips } from 'components/menu-bar/trucks-chip';
import { useRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import type { Flow } from 'flows/flows';
import { useCallback, useRef, useState } from 'react';
import generatePDF, { Margin } from 'react-to-pdf';
import { SnackbarUtils } from 'services/snackbar.service';
import { StyledTableCell, StyledTableRow } from 'simulation/styled-table';
import { useAppSelector } from 'store';
import { formatTime } from 'utils/time';
import { DisplayStationsCell } from './display-stations-cell';
import { DisplayTriggersCell } from './display-triggers-cell';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  table: {
    '.MuiTableCell-root': {
      border: '1px solid lightgray !important',
    },
  },
};

export function SummaryFlowsModal(): JSX.Element {
  const [openSummary, setOpenSummary] = useState(false);
  const handleOpenSummaryModal = (): void => setOpenSummary(true);
  const handleCloseSummaryModal = (): void => setOpenSummary(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const flows = useAppSelector((state) => state.flows.flows);
  const project = useAppSelector((state) => state.project);

  const [robots] = useRobotsEmulation();

  const [generatingPdf, setGeneratingPdf] = useState(false);
  const handleDownloadPdf = useCallback(async () => {
    const el = modalContentRef.current;
    if (!el) {
      return;
    }

    const projectName = project?.projectName ?? 'unknown';
    const time = new Date();
    const filename = `${projectName} flows - ${formatTime(time)}.pdf`;

    setGeneratingPdf(true);
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, 1000)
    );

    try {
      await generatePDF(() => el, {
        page: {
          margin: Margin.SMALL,
        },
        filename,
      });
    } catch (error) {
      SnackbarUtils.error('An error occured while generating the PDF');
    }

    setGeneratingPdf(false);
  }, [project]);

  const getRobotPicturesByFlow = useCallback(
    (flow: Flow) => {
      const robotList =
        flow.robotsAssigned === 'all' ? robots : robots.filter((robot) => flow.robotsAssigned?.includes(robot.serial));

      const truckCounts: Record<string, number> = {};
      robotList.forEach((robot) => {
        truckCounts[robot.picturePath] = (truckCounts[robot.picturePath] || 0) + 1;
      });

      return Object.entries(truckCounts);
    },
    [robots]
  );

  return (
    <>
      <Tooltip title="Summary" placement="top" arrow>
        <IconButton aria-label="settings" onClick={handleOpenSummaryModal}>
          <Visibility sx={{ color: grey[500] }} />
        </IconButton>
      </Tooltip>

      <Modal
        open={openSummary}
        onClose={handleCloseSummaryModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSummary}>
          <Stack spacing={2} sx={style}>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              {/* Title on the left */}
              <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 600, color: 'gray' }}>
                Flows
              </Typography>

              {/* Icons on the right */}
              {!generatingPdf ? (
                <IconButton onClick={handleDownloadPdf}>
                  <FileDownloadIcon />
                </IconButton>
              ) : (
                <CircularProgress size={25} />
              )}
              <IconButton onClick={handleCloseSummaryModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box component="div">
              <TableContainer ref={modalContentRef} component={Paper} sx={{ minWidth: 1200 }}>
                <Table aria-label="flows summary">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Flow name</StyledTableCell>
                      <StyledTableCell>Triggers</StyledTableCell>
                      <StyledTableCell>Stations</StyledTableCell>
                      <StyledTableCell>Robot assignation</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flows.map((flow) => (
                      <StyledTableRow key={flow.id}>
                        <StyledTableCell>
                          <Stack>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                              {flow.name}
                            </Typography>
                            {flow.objective && (
                              <Typography variant="body1" sx={{ color: 'gray' }}>
                                Objective {flow.objective} task{flow.objective > 1 && 's'}/hr
                              </Typography>
                            )}
                            {flow.maximumTaskTime && (
                              <Typography variant="body1" sx={{ color: 'gray' }}>
                                Maximum task time {flow.maximumTaskTime}s
                              </Typography>
                            )}
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell>
                          <DisplayTriggersCell flow={flow} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <DisplayStationsCell flow={flow} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {flow.robotsAssigned && flow.robotsAssigned?.length > 0 ? (
                            <TrucksChips
                              trucksPictures={getRobotPicturesByFlow(flow)}
                              maxModelsEnableDenseMode={0}
                              alwaysExpand
                            />
                          ) : (
                            <Typography variant="body1">No robot assigned</Typography>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Fade>
      </Modal>
    </>
  );
}
