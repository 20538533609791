import { Editor } from 'components/editor';
import { setRender2Mode } from 'editor/editor';
import { Suspense, lazy, useLayoutEffect, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';

/**
 * Load conditionally the 3D or 2D version of Road Editor
 */
export function EditorEngine(): JSX.Element {
  const libCirEnabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const displayPlayground = useAppSelector((state) => state.editor.displayPlayground);
  const dispatch = useAppDispatch();

  const [EditorLibCir, setEditorLibCir] = useState<React.LazyExoticComponent<() => JSX.Element> | null>(null);

  useLayoutEffect(() => {
    if (!libCirEnabled) return;

    // if needed, we load the lib3d component
    try {
      const LazyEditorLibCir = lazy(() =>
        import('../components/editor/editorLibCir.tsx').then((module) => ({ default: module.EditorLibCir }))
      );
      setEditorLibCir(() => LazyEditorLibCir);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while loading libCir', e);
      SnackbarUtils.error(
        `Error while loading the 3D renderer. It is likely to be a cache issue. Please refresh your browser.`
      );

      // we reset the editor to the 2D version
      dispatch(setRender2Mode({ enableRender2: false }));
    }
  }, [dispatch, libCirEnabled]);

  if (!displayPlayground) {
    return <></>;
  }

  if (!libCirEnabled) {
    return <Editor />;
  }

  return <Suspense fallback={<></>}>{EditorLibCir && <EditorLibCir />}</Suspense>;
}
