import { Box } from '@mui/system';
import { DisplayActiveModes } from 'components/editor/displayActiveModes';
import { RightClickMenu } from 'components/editor/right-click-menu';
import { MapImagePropertiesComponent as MapImageProperties } from 'components/import/map-image/import-map-image.properties';
import { LayersBottomLeft } from 'components/layers/layers-bottom-left';
import { FullPageLayout } from 'components/layouts';
import { Properties as ShapesProperties } from 'components/properties/properties';
import { Toolboxes } from 'components/toolboxes';
import { Tools } from 'models/tools';
import { DisplaySimulationTablesView } from 'simulation/displaySimulationTablesView';
import { useAppSelector } from 'store';
import { EditorEngine } from './editor-engine';

interface EditorPageProps {}

export function EditorPage(props: EditorPageProps): JSX.Element {
  const activeTool = useAppSelector((state) => state.tool.activeTool);

  return (
    <FullPageLayout>
      <EditorEngine />
      <DisplayActiveModes />
      {activeTool !== Tools.SimulationConfiguration && <LayersBottomLeft />}
      {activeTool === Tools.SimulationConfiguration && <DisplaySimulationTablesView />}
      <RightClickMenu />
      <ShapesProperties />
      <MapImageProperties />
      <Box
        component="span"
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Toolboxes />
      </Box>
    </FullPageLayout>
  );
}
