import {
  Adjust as AdjustIcon,
  Crop54 as Crop54Icon,
  EventNote,
  PanTool as PanToolIcon,
  SmartToy,
} from '@mui/icons-material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HeightIcon from '@mui/icons-material/Height';
import HubIcon from '@mui/icons-material/Hub';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import RouteIcon from '@mui/icons-material/Route';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import TimelineIcon from '@mui/icons-material/Timeline';
import { updateTools } from 'actions';
import { RackIcon, StockZoneIcon } from 'components/core/icons';
import { Tools } from 'models/tools';
import { checkPermission } from 'services/check-permission';
import type { UserPermissions } from 'shared';
import store from 'store';

const FEATURE_FLAG_SIMULATION = import.meta.env.VITE_FEATURE_FLAG_SIMULATION === 'true';

export interface ToolInfo {
  name: string;
  tool: Tools;
  icon: typeof StockZoneIcon | typeof PanToolIcon;
  hotkey?: string;
  help?: string;
}

export interface ToolInfoWithExtraData extends ToolInfo {
  projectRequired?: boolean | undefined;
  simulationFeatureFlag?: boolean | undefined;
  authorized: boolean;
  permissionName?: UserPermissions;
}

export const TOOL_LIST_CIRCUIT_INITIAL_STATE: ToolInfoWithExtraData[] = [
  {
    name: 'Move',
    tool: Tools.Move,
    icon: PanToolIcon,
    hotkey: 'Escape',
    authorized: true,
  },
  {
    name: 'Draw Segment or Turn',
    help: 'Press [CTRL] while dragging to draw a turn',
    tool: Tools.DrawSegmentOrTurn,
    icon: BorderColorIcon,
    hotkey: 'W',
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Shape',
    tool: Tools.DrawShape,
    icon: TimelineIcon,
    hotkey: 'Q',
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Point',
    tool: Tools.DrawPoint,
    icon: AdjustIcon,
    hotkey: 'P',
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Stock Zone',
    tool: Tools.DrawStockZone,
    icon: StockZoneIcon,
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Rack',
    tool: Tools.DrawRack,
    icon: RackIcon,
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Zone',
    tool: Tools.DrawZone,
    icon: Crop54Icon,
    hotkey: 'Z',
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Draw Measurer (or guide)',
    tool: Tools.DrawMeasurer,
    icon: HeightIcon,
    hotkey: 'M',
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Add Device',
    tool: Tools.AddDevice,
    icon: KeyboardAltIcon,
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
  {
    name: 'Add Note',
    tool: Tools.AddNote,
    icon: SpeakerNotesIcon,
    hotkey: 'N',
    authorized: true,
  },
  {
    name: 'Predefined Shapes',
    help: 'Library of predefined set of shapes to import into the circuit',
    tool: Tools.PredefinedShapes,
    icon: BorderColorIcon,
    authorized: true,
    permissionName: 'edit:circuit' as UserPermissions,
  },
];

export const TOOL_LIST_TRAFFIC_INITIAL_STATE: ToolInfoWithExtraData[] = [
  {
    name: 'Move',
    tool: Tools.Move,
    icon: PanToolIcon,
    hotkey: 'Escape',
    authorized: true,
  },
  {
    name: 'Route',
    tool: Tools.Route,
    icon: RouteIcon,
    projectRequired: true,
    authorized: true,
    permissionName: 'open:traffic_tools' as UserPermissions,
  },
  {
    name: 'Display Traffic',
    tool: Tools.DisplayTraffic,
    icon: LinearScaleIcon,
    projectRequired: true,
    authorized: true,
    permissionName: 'open:traffic_tools' as UserPermissions,
  },
];

export const TOOL_LIST_FLOW_INITIAL_STATE: ToolInfoWithExtraData[] = [
  {
    name: 'Move',
    tool: Tools.Move,
    icon: PanToolIcon,
    hotkey: 'Escape',
    authorized: true,
  },
  {
    name: 'Flow Configuration',
    tool: Tools.FlowConfiguration,
    icon: ModeOfTravelIcon,
    simulationFeatureFlag: true,
    authorized: true,
    permissionName: 'edit:flows' as UserPermissions,
  },
  {
    name: 'Scheduler Configuration',
    tool: Tools.SchedulerConfiguration,
    icon: EventNote,
    projectRequired: false,
    simulationFeatureFlag: true,
    authorized: true,
    permissionName: 'edit:simulation_configuration' as UserPermissions,
  },
  {
    name: 'Robots Simulation Configuration',
    tool: Tools.RobotsSimulationConfiguration,
    icon: SmartToy,
    projectRequired: true,
    simulationFeatureFlag: true,
    authorized: true,
    permissionName: 'edit:simulation_configuration' as UserPermissions,
  },
  {
    name: 'Simulation Configuration',
    tool: Tools.SimulationConfiguration,
    icon: HubIcon,
    projectRequired: true,
    simulationFeatureFlag: true,
    authorized: true,
    permissionName: 'edit:simulation_configuration' as UserPermissions,
  },
].filter((tool) => {
  if (tool.simulationFeatureFlag) {
    return FEATURE_FLAG_SIMULATION;
  }

  return true;
});

export const TOOL_LIST_ALL_TOOLS = [
  ...TOOL_LIST_CIRCUIT_INITIAL_STATE,
  ...TOOL_LIST_TRAFFIC_INITIAL_STATE,
  ...TOOL_LIST_FLOW_INITIAL_STATE,
];

window && window.addEventListener('updateToolsAuth', updateToolsAuth);

/**
 * This function mutates the tools objects and update their authorization state
 */
export async function updateToolsAuth(): Promise<void> {
  const toolsState = store.getState().tool;
  const circuitTools = toolsState.circuitTools;
  const flowTools = toolsState.flowTools;
  const trafficTools = toolsState.trafficTools;

  let newCircuitTools: typeof circuitTools | undefined = undefined;
  let newFlowTools: typeof flowTools | undefined = undefined;
  let newTrafficTools: typeof trafficTools | undefined = undefined;

  for (let i = 0; i < circuitTools.length; i++) {
    const tool = circuitTools[i];
    if (tool.permissionName) {
      const authorized = await checkPermission(tool.permissionName);
      if (authorized !== tool.authorized) {
        if (!newCircuitTools) newCircuitTools = [...circuitTools];

        newCircuitTools[i] = {
          ...tool,
          authorized,
        };
      }
    }
  }

  for (let i = 0; i < flowTools.length; i++) {
    const tool = flowTools[i];
    if (tool.permissionName) {
      const authorized = await checkPermission(tool.permissionName);
      if (authorized !== tool.authorized) {
        if (!newFlowTools) newFlowTools = [...flowTools];

        newFlowTools[i] = {
          ...tool,
          authorized,
        };
      }
    }
  }

  for (let i = 0; i < trafficTools.length; i++) {
    const tool = trafficTools[i];
    if (tool.permissionName) {
      const authorized = await checkPermission(tool.permissionName);
      if (authorized !== tool.authorized) {
        if (!newTrafficTools) newTrafficTools = [...trafficTools];

        newTrafficTools[i] = {
          ...tool,
          authorized,
        };
      }
    }
  }

  if (newCircuitTools || newFlowTools || newTrafficTools) {
    store.dispatch(
      updateTools({
        circuitTools: newCircuitTools,
        flowTools: newFlowTools,
        trafficTools: newTrafficTools,
      })
    );
  }
}
