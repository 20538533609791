import { Troubleshoot } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import type { PerfoRecordData } from 'simulation/perfo-data';
import type { SimulationTask } from 'simulation/simulation';
import { useAppSelector } from 'store';
import { formatTime } from 'utils/time';
import { isDevVersion } from '../../../index';

interface AggregatedRecord {
  taskId: string | number;
  taskName: string;
  recordId: string;
  startTime: number;
  endTime: number;
  robotId: number;
  category: string;
  stepId: string;
}

/**
 * Button component that extracts all records from all tasks and downloads them as a CSV file.
 * Only visible in development mode.
 */
export function ExtractRecordsButton(): JSX.Element | null {
  const tasks = useAppSelector((state) => state.simulation.tasks);
  const projectName = useAppSelector((state) => state.project.projectName) ?? 'unknown';

  // Only show in development mode
  if (!isDevVersion()) {
    return null;
  }

  const handleExtractRecords = (): void => {
    const csvHeader = 'task_id;task_name;record_id;start_time;end_time;robot_id;category;step_id\n';
    const aggregatedRecords = new Map<string, AggregatedRecord>();

    Object.values(tasks).forEach((task: SimulationTask) => {
      if (!task.records) return;

      // Group records by category
      const recordsByCategory: { [key: string]: PerfoRecordData[] } = {};
      Object.values(task.records).forEach((record) => {
        const category = record.categoryLabel ?? '';
        if (!recordsByCategory[category]) {
          recordsByCategory[category] = [];
        }

        recordsByCategory[category].push(record);
      });

      // Process records by category
      Object.entries(recordsByCategory).forEach(([category, categoryRecords]) => {
        const recordsIdsToSkip = new Set<string>();

        categoryRecords.forEach((record, index) => {
          if (recordsIdsToSkip.has(record.recordID.toString())) return;

          const beginEnd = record.beginend;
          let associatedRecord: PerfoRecordData | undefined = undefined;

          if (beginEnd === 0) {
            // Find matching end record
            for (let i = index + 1; i < categoryRecords.length; i++) {
              const record2 = categoryRecords[i];
              if (record2.beginend === 1) {
                associatedRecord = record2;
                break;
              }
            }
          } else if (beginEnd === 1) {
            // Find matching start record
            for (let i = index - 1; i >= 0; i--) {
              const record2 = categoryRecords[i];
              if (record2.beginend === 0) {
                associatedRecord = record2;
                break;
              }
            }
          }

          if (associatedRecord) {
            recordsIdsToSkip.add(associatedRecord.recordID.toString());
          }

          if (beginEnd === 0 || (beginEnd === 1 && !associatedRecord)) {
            const key = `${task.id}_${record.recordID}`;
            aggregatedRecords.set(key, {
              taskId: task.id,
              taskName: task.taskName,
              recordId: record.recordID.toString(),
              startTime: beginEnd === 0 ? record.timestamp : associatedRecord?.timestamp ?? Infinity,
              endTime: beginEnd === 0 ? associatedRecord?.timestamp ?? -Infinity : record.timestamp,
              robotId: record.robotID,
              category: record.categoryLabel ?? '',
              stepId: record.stepID.toString() ?? '',
            });
          }
        });
      });
    });

    // Convert aggregated records to CSV rows
    const csvRows: string[] = Array.from(aggregatedRecords.values()).map((record) => {
      return [
        record.taskId,
        record.taskName,
        record.recordId,
        record.startTime === Infinity ? '' : record.startTime.toFixed(3),
        record.endTime === -Infinity ? '' : record.endTime.toFixed(3),
        record.robotId,
        record.category,
        record.stepId,
      ].join(';');
    });

    // Combine header and rows
    const csvContent = csvHeader + csvRows.join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    // Generate filename with metadata
    const timestamp = formatTime(new Date());
    const filename = `${projectName}_records_${timestamp}.csv`;

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title="Extract Records (for performance analysis)">
      <IconButton onClick={handleExtractRecords} size="small">
        <Troubleshoot />
      </IconButton>
    </Tooltip>
  );
}
