import { useEffect } from 'react';
import { AuthService } from 'services/auth.service';
import { Container } from 'typescript-ioc';

export function useAutoRefreshToken(): void {
  useEffect(() => {
    const authService = Container.get(AuthService);

    const refresh = (): void => {
      if (authService.authenticated) {
        // eslint-disable-next-line no-console
        console.log('Refreshing token');
        authService.refreshToken().subscribe({
          next: () => {
            // eslint-disable-next-line no-console
            console.log('Token refreshed successfully');
          },
          error: (err) => {
            // eslint-disable-next-line no-console
            console.error('Error refreshing token:', err);
          },
        });
      }
    };

    if (navigator.onLine) {
      refresh();
    }
  }, []);
}
