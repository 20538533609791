import { Edit } from '@mui/icons-material';
import { Alert, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { selectToolAction } from 'actions';
import { setSelectedFlowId } from 'flows/flows';
import { Tools } from 'models/tools';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

export function AlertUsedNotAllowedName(): JSX.Element {
  const flows = useAppSelector((state) => state.flows.flows);
  const stations = useAppSelector((state) => state.flows.stations);

  const dispatch = useAppDispatch();

  const flowsWithBadName = useMemo(() => {
    return flows.filter((flow) => flow.name.includes('/'));
  }, [flows]);

  const stationsWithBadName = useMemo(() => {
    return stations.filter((station) => station.name.includes('/'));
  }, [stations]);

  const handleClickStation = useCallback(
    (stationId: string) => {
      dispatch(setSelectedFlowId(stationId));

      dispatch(
        selectToolAction({
          toolName: Tools.StationsConfiguration,
        })
      );
    },
    [dispatch]
  );

  const handleClickFlow = useCallback(
    (flowId: string) => {
      dispatch(setSelectedFlowId(flowId));

      dispatch(
        selectToolAction({
          toolName: Tools.FlowConfiguration,
        })
      );
    },
    [dispatch]
  );

  const hasNotAllowedName = flowsWithBadName.length === 0 && stationsWithBadName.length === 0;
  const totalLength = flowsWithBadName.length + stationsWithBadName.length;

  if (hasNotAllowedName) return <></>;

  return (
    <Alert
      severity="warning"
      sx={{
        marginBottom: theme.spacing(1),
      }}
    >
      The following configuration{totalLength !== 1 ? 's contain' : ' contains'} the forbidden '/' character in{' '}
      {totalLength !== 1 ? 'their' : 'its'} name:
      <List dense>
        {flowsWithBadName.length !== 0 && (
          <>
            Flow:
            {flowsWithBadName.map((flow) => (
              <ListItem
                key={flow.id}
                secondaryAction={
                  <IconButton onClick={() => handleClickFlow(flow.id)} edge="end" size="small">
                    <Edit />
                  </IconButton>
                }
              >
                <ListItemText primary={flow.name} />
              </ListItem>
            ))}
          </>
        )}

        {stationsWithBadName.length !== 0 && (
          <>
            Station:
            {stationsWithBadName.map((station) => (
              <ListItem
                key={station.id}
                secondaryAction={
                  <IconButton onClick={() => handleClickStation(station.id)} edge="end" size="small">
                    <Edit />
                  </IconButton>
                }
              >
                <ListItemText primary={station.name} />
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Alert>
  );
}
