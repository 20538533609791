import { Alert, AlertTitle } from '@mui/material';

interface AlertVnaPerformanceProps {
  robotsSimulationData?: {
    robotId: number;
    name: string;
    serial: string;
    modelName: string;
    batteryModel?: string;
    maxForwardSpeed?: number;
    maxBackwardSpeed?: number;
    maxLateralSpeed?: number;
    traveledDistance?: number;
    timers?: any;
  }[];
}

export function AlertVnaPerformance({ robotsSimulationData }: AlertVnaPerformanceProps): JSX.Element {
  const veenyNamesKeywords = ['vna', 'veeny', 'kmatic', 'k-gen2'];

  // Only show alert if there are VNA robots
  const hasVnaRobots = robotsSimulationData?.some((robot) =>
    veenyNamesKeywords.some((keyword) => robot.modelName.toLowerCase().includes(keyword))
  );

  if (!hasVnaRobots) {
    return <></>;
  }

  return (
    <Alert
      severity="warning"
      sx={{
        marginBottom: '10px',
      }}
    >
      <AlertTitle>VNA throughput accuracy is being improved</AlertTitle>
      Some throughputs analysis have been performed and the values are in some cases extremely underestimated. A new
      version will be published to fix this issue. Please have a thorough review before selling a VNA project.
    </Alert>
  );
}
