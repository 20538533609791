import type { AlignAction } from 'drawings/elements/align';
import type { UpdatedShapesAction } from 'epics/circuit.epic';
import type { Position } from 'geojson';
import type {
  Circuit,
  CircuitShape,
  CircuitShapes,
  DeviceType,
  GabaritProperties,
  RackCellTemplate,
  SegmentDataInPoint,
  TurnProperties,
} from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import type { CircuitState, LayerData, LayerGroupData, LayersDataContainer } from 'reducers/circuit/state';
import type { SelectedShapeData } from 'reducers/local/state';
import type { Action } from 'redux';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import {
  isCircuitDevice,
  isCircuitMeasurer,
  isCircuitNote,
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
  isCircuitTurn,
  isCircuitZone,
} from 'utils/circuit/shape-guards';
import { roundCoordinates } from 'utils/helpers';
import type { DeleteAction } from 'utils/redux';
import type { DeleteDevice, SaveDevice } from './devices';
import { deleteDeviceAction, saveDeviceAction } from './devices';
import type { DeleteMeasurer, SaveMeasurer } from './measurers';
import { deleteMeasurerAction, saveMeasurerAction } from './measurers';
import type { DeleteNote, SaveNote } from './notes';
import { deleteNoteAction, saveNoteAction } from './notes';
import type { DeletePoint, SavePoint } from './points';
import { deletePointAction, savePointAction } from './points';
import type { DeleteRack, SaveRack, SaveRackSuccess } from './racks';
import { deleteRackAction, saveRackSuccessAction } from './racks';
import type { DeleteSegment, SaveSegment } from './segment';
import { deleteSegmentAction, saveSegmentAction } from './segment';
import type { Failure } from './shared';
import { failureAction } from './shared';
import type { DeleteStockZone, SaveStockZone } from './stock-zones';
import { deleteStockZoneAction, saveStockZoneAction } from './stock-zones';
import type { DeleteTurn, SaveTurn } from './turns';
import { deleteTurnAction, saveTurnAction } from './turns';
import type { SaveZone } from './zones';
import { deleteZoneAction, saveZoneAction } from './zones';

export enum CircuitActionTypes {
  SaveCircuitToHistory = '[Circuit] Save Circuit To History',
  ApplyUpdatedShapesFromYJS = '[Circuit] Apply Updated Shapes From YJS',
  LoadCircuitFromYJS = '[Circuit] Load Circuit From YJS',
  ImportCircuit = '[Circuit] Import Circuit',
  ImportCircuitSuccess = '[Circuit] Import Circuit Success',
  ImportCircuitFailure = '[Circuit] Import Circuit Failure',
  DeleteSelectedShapes = '[Circuit] Delete Selected Shapes',
  DeleteSelectedShapesSuccess = '[Circuit] Delete Selected Shapes Success',
  DeleteAllShapes = '[Circuit] Delete All Shapes',
  DeleteAllShapesSuccess = '[Circuit] Delete All Shapes Success',
  SelectShape = '[Circuit] Select Shape',
  SelectMultipleShapes = '[Circuit] Select Multiple Shapes',
  SelectShapesInRect = '[Circuit] Select Shapes In Rectangle',
  UnselectShape = '[Circuit] Unselect Shape',
  UnselectSeveralShapes = '[Circuit] Unselect Several Shapes',
  ClearShapesSelection = '[Circuit] Clear Shapes selection',
  AddZone = '[Circuit] Add Zone',
  AddZoneFailure = '[Circuit] Add Zone Failure',
  AddStockZone = '[Circuit] Add Stock Zone',
  AddStockZoneFailure = '[Circuit] Add Stock Zone Failure',
  AddRack = '[Circuit] Add Rack',
  AddRackFailure = '[Circuit] Add Rack Failure',
  AddPoint = '[Circuit] Add Point',
  AddPointFailure = '[Circuit] Add Point Failure',
  AddSegment = '[Circuit] Add Segment',
  AddSegmentFailure = '[Circuit] Add Segment Failure',
  AddMultipleSegments = '[Circuit] Add Multiple Segments',
  AddMeasurer = '[Circuit] Add Measurer',
  AddMeasurerFailure = '[Circuit] Add Measurer Failure',
  AddTurn = '[Circuit] Add Turn',
  AddTurnFailure = '[Circuit] Add Turn Failure',
  UpdateTurn = '[Circuit] Update Turn',
  UpdateTurnFailure = '[Circuit] Update Turn failure',
  AddDevice = '[Circuit] Add Device',
  AddNote = '[Circuit] Add Note',
  AddDeviceFailure = '[Circuit] Add Device Failure',
  SegmentMoved = '[Circuit] Segment moved',
  MultipleSegmentsMoved = '[Circuit] Multiple segments moved',
  PointMoved = '[Circuit] Point moved',
  ChangeLockState = '[Circuit] Change shape locking state',
  ChangeLockStateSelection = '[Circuit] Change selection locking state',
  AttachMeasurer = '[Circuit] Attach Measurer end point',
  DetachMeasurer = '[Circuit] Detach Measurer end point',
  UpdateMeasurer = '[Circuit] Update Measurer geometry',
  ImportLayers = '[Circuit] Import Layers',
  AddLayer = '[Circuit] Add Layer',
  UpdateLayer = '[Circuit] Update Layer',
  DeleteLayer = '[Circuit] Delete Layer',
  ChangeLayersOrder = '[Circuit] Changer Layers Order',
  ChangeDisplayStateParameters = '[Circuit] Open/close the layer properties',
  AddLayerGroup = '[Circuit] Add Layer Group',
  UpdateLayerGroup = '[Circuit] Update Layer Group',
  DeleteLayerGroup = '[Circuit] Delete Layer Group',
  CopyAllShapes = '[Circuit] Copy all shapes from a layer to another one',
  BringToFront = '[Circuit] Bring to front',
  BringToBack = '[Circuit] Bring to back',
  DeleteAllCellTemplates = '[Circuit] Delete all cell templates',
  ImportCellTemplates = '[Circuit] Import cell templates',
  UpdateSegmentsPortions = '[Circuit] Update segments portions',
  MoveSelection = '[Circuit] Move selection',
  RotateSelection = '[Circuit] Rotate selection',
  TransferToAnotherLayer = '[Circuit] Transfer to another layer',
  AskUpdateRack = '[Circuit] Ask update rack',
  RemoveAllGabarits = '[Circuit] Remove All Gabarits',
  ChangeShapeVisibility = '[Circuit] Change shape visibility',
  DeleteMultipleShapes = '[Circuit] Delete multiple shapes',
  DeleteMultipleTurnSuccess = '[Circuit] Delete multiple turns success',
  RenameShape = '[Circuit] Rename shape using "Search a shape"',
  UpdateGabaritState = '[Circuit] Update the gabarit of all the shape present in the layer',
  EnableAllGabarits = '[Circuit] Enable all gabarits of all the shape present in the layer',
  AlignElement = '[Circuit] Align rack and stockZone vertically or horizontally',
  CleanUpExcessSegmentLengths = '[Circuit] Clean up excess segment lengths for all the segment present in the layer',
}

export interface RoadEditorAction extends Action {
  payload: {
    userAction?: boolean;
  };
}

export interface RenameShape extends Action {
  type: CircuitActionTypes.RenameShape;
  payload: { name: string; shape: CircuitShape };
}

export function renameShapeAction(
  payload: RenameShape['payload']
):
  | SaveSegment
  | SavePoint
  | SaveStockZone
  | SaveMeasurer
  | SaveRackSuccess
  | SaveRack
  | SaveTurn
  | SaveDevice
  | SaveNote
  | SaveZone {
  const { name, shape } = payload;

  const shapeType = shape.properties.type;

  if (isCircuitSegment(shape)) {
    return saveSegmentAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitPoint(shape)) {
    return savePointAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitStockZone(shape)) {
    return saveStockZoneAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitMeasurer(shape)) {
    return saveMeasurerAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitRack(shape)) {
    return saveRackSuccessAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitTurn(shape)) {
    return saveTurnAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitDevice(shape)) {
    return saveDeviceAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitZone(shape)) {
    return saveZoneAction({ ...shape, properties: { ...shape.properties, name } });
  } else if (isCircuitNote(shape)) {
    return saveNoteAction({ ...shape, properties: { ...shape.properties, name } });
  }

  assert<Equals<typeof shape, never>>();

  throw new Error(`Unknown shape type ${shapeType}`);
}

export interface SaveCircuitToHistory extends Action {
  type: CircuitActionTypes.SaveCircuitToHistory;
  payload: RoadEditorAction['payload'];
}

export function saveCircuitToHistoryAction(): SaveCircuitToHistory {
  return { type: CircuitActionTypes.SaveCircuitToHistory, payload: { userAction: true } };
}

export interface ApplyUpdatedShapesFromYJS extends Action {
  type: CircuitActionTypes.ApplyUpdatedShapesFromYJS;
  payload: UpdatedShapesAction[];
}

export function applyUpdatedShapesFromYJSAction(
  payload: ApplyUpdatedShapesFromYJS['payload']
): ApplyUpdatedShapesFromYJS {
  return { type: CircuitActionTypes.ApplyUpdatedShapesFromYJS, payload };
}

export interface LoadCircuitFromYJS extends Action {
  type: CircuitActionTypes.LoadCircuitFromYJS;
  payload: { circuit: CircuitState } & RoadEditorAction['payload'];
}

export function loadCircuitFromYJSAction(payload: LoadCircuitFromYJS['payload']): LoadCircuitFromYJS {
  return { type: CircuitActionTypes.LoadCircuitFromYJS, payload };
}

export interface ImportCircuit extends Action {
  type: CircuitActionTypes.ImportCircuit;
  payload: { shapes: CircuitShapes } & RoadEditorAction['payload'];
}

export function importCircuitAction(payload: ImportCircuit['payload']): ImportCircuit {
  return { type: CircuitActionTypes.ImportCircuit, payload };
}

export interface ImportCircuitSuccess extends Action {
  type: CircuitActionTypes.ImportCircuitSuccess;
  payload: { importedCircuit: Circuit } & RoadEditorAction['payload'];
}

export function importCircuitSuccessAction(payload: ImportCircuitSuccess['payload']): ImportCircuitSuccess {
  return { type: CircuitActionTypes.ImportCircuitSuccess, payload };
}

export interface ImportCircuitFailure extends Failure<CircuitActionTypes.ImportCircuitFailure> {}

export function importCircuitFailureAction(error: Error): ImportCircuitFailure {
  return failureAction(CircuitActionTypes.ImportCircuitFailure, error);
}

export interface ImportCellTemplates extends Action {
  type: CircuitActionTypes.ImportCellTemplates;
  payload: { cellTemplates: Record<string, RackCellTemplate> } & RoadEditorAction['payload'];
}
export function importCellTemplatesAction(payload: ImportCellTemplates['payload']): ImportCellTemplates {
  return { type: CircuitActionTypes.ImportCellTemplates, payload };
}

export interface SelectShape extends Action {
  type: CircuitActionTypes.SelectShape;
  payload: {
    selectedShapeId: string;
    selectedShapeType: ShapeTypes;
    shape?: CircuitShape;
  } & RoadEditorAction['payload'];
}

export function selectCircuitShapeAction(payload: SelectShape['payload']): SelectShape {
  return { type: CircuitActionTypes.SelectShape, payload };
}

export interface ShapeToSelectPayload {
  id: string;
  type: ShapeTypes;
  shape?: CircuitShape;
}

export interface SelectMultipleShapes extends Action {
  type: CircuitActionTypes.SelectMultipleShapes;
  payload: ShapeToSelectPayload[];
}

export function selectMultipleCircuitShapesAction(payload: SelectMultipleShapes['payload']): SelectMultipleShapes {
  return { type: CircuitActionTypes.SelectMultipleShapes, payload };
}

export interface SelectShapesInRect extends Action {
  type: CircuitActionTypes.SelectShapesInRect;
  payload: {
    x: number;
    y: number;
    x2: number;
    y2: number;
  } & RoadEditorAction['payload'];
}

export function selectCircuitShapesInRectAction(payload: SelectShapesInRect['payload']): SelectShapesInRect {
  return { type: CircuitActionTypes.SelectShapesInRect, payload };
}

export interface DeleteSelectedShapes extends Action {
  type: CircuitActionTypes.DeleteSelectedShapes;
  payload?: RoadEditorAction['payload'];
}

export function deleteSelectedCircuitShapesAction(manual?: boolean): DeleteSelectedShapes {
  return {
    type: CircuitActionTypes.DeleteSelectedShapes,
    payload: {
      userAction: !!manual,
    },
  };
}

export interface DeleteSelectedShapesSuccess extends Action {
  type: CircuitActionTypes.DeleteSelectedShapesSuccess;
  payload?: RoadEditorAction['payload'];
}

export function deleteSelectedCircuitShapesSuccessAction(): DeleteSelectedShapesSuccess {
  return { type: CircuitActionTypes.DeleteSelectedShapesSuccess };
}

export interface DeleteAllCellTemplates extends Action {
  type: CircuitActionTypes.DeleteAllCellTemplates;
  payload?: RoadEditorAction['payload'];
}

export function deleteAllCellTemplatesAction(): DeleteAllCellTemplates {
  return { type: CircuitActionTypes.DeleteAllCellTemplates };
}

export interface DeleteAllShapes extends Action {
  type: CircuitActionTypes.DeleteAllShapes;
  payload?: RoadEditorAction['payload'];
}

export function restoreCircuitInitialStateAction(): DeleteAllShapes {
  return { type: CircuitActionTypes.DeleteAllShapes };
}

export interface DeleteAllShapesSuccess extends Action {
  type: CircuitActionTypes.DeleteAllShapesSuccess;
  payload?: RoadEditorAction['payload'];
}

export function deleteAllShapesSuccessAction(): DeleteAllShapesSuccess {
  return { type: CircuitActionTypes.DeleteAllShapesSuccess };
}

export interface UnselectShape extends Action {
  type: CircuitActionTypes.UnselectShape;
  payload: { unselectedShapeId: string; unselectedShapeType: ShapeTypes } & RoadEditorAction['payload'];
}

export function unselectCircuitShapeAction(payload: UnselectShape['payload']): UnselectShape {
  return { type: CircuitActionTypes.UnselectShape, payload };
}

interface ShapeToSelectPayloadId extends Omit<ShapeToSelectPayload, 'type'> {
  type?: ShapeTypes;
}

export interface UnselectSeveralShapes extends Action {
  type: CircuitActionTypes.UnselectSeveralShapes;
  payload: { unselectedShapes: ShapeToSelectPayloadId[] } & RoadEditorAction['payload'];
}

export function unselectSeveralCircuitShapesAction(payload: UnselectSeveralShapes['payload']): UnselectSeveralShapes {
  return { type: CircuitActionTypes.UnselectSeveralShapes, payload };
}

export interface ClearShapesSelection extends Action {
  type: CircuitActionTypes.ClearShapesSelection;
  payload?: RoadEditorAction['payload'];
}

export function clearShapesSelectionAction(): ClearShapesSelection {
  return { type: CircuitActionTypes.ClearShapesSelection };
}

export interface AddZone extends Action {
  type: CircuitActionTypes.AddZone;
  payload: {
    coord: number[][];
    zoneType: 'Rectangle' | 'Triangle';
    layerId?: string;
  } & RoadEditorAction['payload'];
}

export function addZoneAction(payload: AddZone['payload']): AddZone {
  payload.coord = payload.coord.map((point) => roundCoordinates(point));

  return { type: CircuitActionTypes.AddZone, payload };
}

export interface AddZoneFailure extends Failure<CircuitActionTypes.AddZoneFailure> {}

export function addZoneFailureAction(error: Error): AddZoneFailure {
  return failureAction(CircuitActionTypes.AddZoneFailure, error);
}

export interface AddStockZone extends Action {
  type: CircuitActionTypes.AddStockZone;
  payload: {
    coord: number[][];
    stockZoneType: 'Conveyor' | 'StockZone' | 'StackZone';
    layerId?: string;
    orientation: number;
  } & RoadEditorAction['payload'];
}

export function addStockZoneAction(payload: AddStockZone['payload']): AddStockZone {
  payload.coord = payload.coord.map((point) => roundCoordinates(point));

  return { type: CircuitActionTypes.AddStockZone, payload };
}

export interface AddStockZoneFailure extends Failure<CircuitActionTypes.AddStockZoneFailure> {}

export function addStockZoneFailureAction(error: Error): AddStockZoneFailure {
  return failureAction(CircuitActionTypes.AddStockZoneFailure, error);
}

export interface AddRack extends Action {
  type: CircuitActionTypes.AddRack;
  payload: {
    coord: number[][];
    layerId?: string;
    orientation: number;
    isConveyor?: boolean;
  } & RoadEditorAction['payload'];
}

export function addRackAction(payload: AddRack['payload']): AddRack {
  payload.coord = payload.coord.map((point) => roundCoordinates(point));

  return { type: CircuitActionTypes.AddRack, payload };
}

export interface AddRackFailure extends Failure<CircuitActionTypes.AddRackFailure> {}

export function AddRackFailureAction(error: Error): AddRackFailure {
  return failureAction(CircuitActionTypes.AddRackFailure, error);
}

export interface AddPoint extends Action {
  type: CircuitActionTypes.AddPoint;
  payload: { coord: number[]; angle: number; layerId?: string } & RoadEditorAction['payload'];
}

export function addPointAction(payload: AddPoint['payload']): AddPoint {
  payload.coord = roundCoordinates(payload.coord);

  return { type: CircuitActionTypes.AddPoint, payload };
}

export interface AddPointFailure extends Failure<CircuitActionTypes.AddPointFailure> {}

export function addPointFailureAction(error: Error): AddPointFailure {
  return failureAction(CircuitActionTypes.AddPointFailure, error);
}

export interface AddDevice extends Action {
  type: CircuitActionTypes.AddDevice;
  payload: {
    coord: number[];
    deviceType: DeviceType;
    layerId?: string;
    IP?: string;
    name?: string;
    displayName?: string;
    frequency?: number;
    network?: string;
    comboxVersion?: string;
    gateway?: string;
    ioCount?: string;
    ioAddresses?: string;
  } & RoadEditorAction['payload'];
}

export function addDeviceAction(payload: AddDevice['payload']): AddDevice {
  payload.coord = roundCoordinates(payload.coord);

  return { type: CircuitActionTypes.AddDevice, payload };
}

export interface AddNote extends Action {
  type: CircuitActionTypes.AddNote;
  payload: {
    coord: number[];
    type: ShapeTypes.NoteShape;
    prio?: number;
    size?: number;
    layerId?: string;
    name?: string;
    gateway?: string;
    IP?: string;
  } & RoadEditorAction['payload'];
}

export function addNoteAction(payload: AddNote['payload']): AddNote {
  payload.coord = roundCoordinates(payload.coord);

  return { type: CircuitActionTypes.AddNote, payload };
}

export interface AddDeviceFailure extends Failure<CircuitActionTypes.AddDeviceFailure> {}

export interface AddSegment extends Action {
  type: CircuitActionTypes.AddSegment;
  payload: {
    coord: number[][];
    layerId?: string;
    stockLine?: string;
    rack?: string;
    rackColumn?: string;
    locked?: boolean;
    id?: string;
    drawShape?: boolean;
    name?: string;
  } & RoadEditorAction['payload'];
}

export function addSegmentAction(payload: AddSegment['payload']): AddSegment {
  payload.coord = payload.coord.map((point) => roundCoordinates(point));

  return { type: CircuitActionTypes.AddSegment, payload };
}

export interface AddSegmentFailure extends Failure<CircuitActionTypes.AddSegmentFailure> {}

export function addSegmentFailureAction(error: Error): AddSegmentFailure {
  return failureAction(CircuitActionTypes.AddSegmentFailure, error);
}

export interface AddMultipleSegments extends Action {
  type: CircuitActionTypes.AddMultipleSegments;
  payload: AddSegment['payload'][];
}

export function AddMultipleSegmentsAction(payload: AddMultipleSegments['payload']): AddMultipleSegments {
  return { type: CircuitActionTypes.AddMultipleSegments, payload };
}

export interface AddMeasurer extends Action {
  type: CircuitActionTypes.AddMeasurer;
  payload: { coord: number[][]; layerId?: string } & RoadEditorAction['payload'];
}

export function addMeasurerAction(payload: AddMeasurer['payload']): AddMeasurer {
  return { type: CircuitActionTypes.AddMeasurer, payload };
}

export interface AddMeasurerFailure extends Failure<CircuitActionTypes.AddMeasurerFailure> {}

export function addMeasurerFailureAction(error: Error): AddMeasurerFailure {
  return failureAction(CircuitActionTypes.AddMeasurerFailure, error);
}

export interface AddTurn extends Action {
  type: CircuitActionTypes.AddTurn;
  payload: {
    layerId?: string;
    origin: {
      id: string;
      position: number; // [0, 1]
      orientation: number;
      coordinates: {
        x: number;
        y: number;
      };
      segment: number[][];
    };
    destination: {
      id: string;
      position: number; // [0, 1]
      orientation: number;
      coordinates: {
        x: number;
        y: number;
      };
      segment: number[][];
    };
    radius?: number;
    maxOvershoot?: number;
    turnType?: TurnProperties['turnType'];
    startPointOffset?: number;
    extendedLength?: boolean;
  } & RoadEditorAction['payload'];
}

export function addTurnAction(payload: AddTurn['payload']): AddTurn {
  return { type: CircuitActionTypes.AddTurn, payload };
}

export interface SegmentMoved extends Action {
  type: CircuitActionTypes.SegmentMoved;
  payload: {
    idSegment: string;
    coordinates?: Position[];
  } & RoadEditorAction['payload'];
}

export function segmentMovedAction(payload: SegmentMoved['payload']): SegmentMoved {
  return { type: CircuitActionTypes.SegmentMoved, payload };
}

export interface MultipleSegmentsMoved extends Action {
  type: CircuitActionTypes.MultipleSegmentsMoved;
  payload: {
    segmentsData: {
      idSegment: string;
      coordinates?: Position[];
    }[];
  } & RoadEditorAction['payload'];
}

export function multipleSegmentsMovedAction(payload: MultipleSegmentsMoved['payload']): MultipleSegmentsMoved {
  return { type: CircuitActionTypes.MultipleSegmentsMoved, payload };
}

export interface PointMoved extends Action {
  type: CircuitActionTypes.PointMoved;
  payload: {
    id: string;
    coordinates?: Position;
    segment?: SegmentDataInPoint | undefined;
  } & RoadEditorAction['payload'];
}

export function pointMovedAction(payload: PointMoved['payload']): PointMoved {
  return { type: CircuitActionTypes.PointMoved, payload };
}

export interface UpdateTurn extends Action {
  type: CircuitActionTypes.UpdateTurn;
  payload: {
    /** id (or ids) of the turns to udpate */
    idToUpdate: string | string[];
    /** new radius of the turn(s) */
    radius?: number;
    /** new type of the turn(s) */
    turnType?: 'Normal' | 'StopBeforeTurn' | 'Tesseract';
    /** new max overshoot of the turn(s) */
    maxOvershoot?: number;
    /** new position factor origin of the turn(s) */
    positionFactorOrigin?: number;
    /** new position factor destination of the turn(s) */
    positionFactorDest?: number;
    /** new gabarit of the turn(s) */
    gabarit?: GabaritProperties;
    /** new start point offset of the turn(s) [m] */
    startPointOffset?: number;
    /** id of the origin segment (i.e. the segment the turn goes from) */
    originId?: string;
    /** id of the destination segment (i.e. the segment the turn goes to) */
    destinationId?: string;
  } & RoadEditorAction['payload'];
}

export function updateTurnAction(payload: UpdateTurn['payload']): UpdateTurn {
  return { type: CircuitActionTypes.UpdateTurn, payload };
}

export interface ChangeLockState extends Action {
  type: CircuitActionTypes.ChangeLockState;
  payload: {
    idShape: string;
    newLockState: boolean;
    shapeType: ShapeTypes;
  } & RoadEditorAction['payload'];
}

export function changeLockStateAction(payload: ChangeLockState['payload']): ChangeLockState {
  return { type: CircuitActionTypes.ChangeLockState, payload };
}

export interface ChangeLockStateSelection extends Action {
  type: CircuitActionTypes.ChangeLockStateSelection;
  payload: {
    newLockState: boolean;
  } & RoadEditorAction['payload'];
}

export function changeLockStateSelectionAction(payload: ChangeLockStateSelection['payload']): ChangeLockStateSelection {
  return { type: CircuitActionTypes.ChangeLockStateSelection, payload };
}

export interface AttachMeasurer extends Action {
  type: CircuitActionTypes.AttachMeasurer;
  payload: {
    endPoint: 0 | 1; // 0 = left end point, 1 = right end point
    measurerId: string;
    shapeToAttach?: string;
  } & RoadEditorAction['payload'];
}

export function attachMeasurerAction(payload: AttachMeasurer['payload']): AttachMeasurer {
  return { type: CircuitActionTypes.AttachMeasurer, payload };
}

export interface DetachMeasurer extends Action {
  type: CircuitActionTypes.DetachMeasurer;
  payload: {
    endPoint: 0 | 1;
    measurerId: string;
  } & RoadEditorAction['payload'];
}

export function detachMeasurerAction(payload: DetachMeasurer['payload']): DetachMeasurer {
  return { type: CircuitActionTypes.DetachMeasurer, payload };
}

export interface UpdateMeasurer extends Action {
  type: CircuitActionTypes.UpdateMeasurer;
  payload: {
    measurerId: string;
    endPointToUpdate?: 0 | 1; // if not defined, we update both
    coordinates?: Position[];
    doNotRecomputeCoordinates?: boolean;
  } & RoadEditorAction['payload'];
}

export function updateMeasurerAction(payload: UpdateMeasurer['payload']): UpdateMeasurer {
  return { type: CircuitActionTypes.UpdateMeasurer, payload };
}

export interface ImportLayers extends Action {
  type: CircuitActionTypes.ImportLayers;
  payload: LayersDataContainer & RoadEditorAction['payload'];
}

export function importLayersAction(payload: ImportLayers['payload']): ImportLayers {
  return { type: CircuitActionTypes.ImportLayers, payload };
}

export interface UpdateLayer extends Action {
  type: CircuitActionTypes.UpdateLayer;
  payload: {
    layerId: string;
    visibility?: boolean;
    selectedLayer?: string;
    name?: string;
    isDraft?: boolean;
    color?: string;
    defaultModel?: string;
    defaultPattern?: string;
    displayGabarits?: boolean;
  } & RoadEditorAction['payload'];
}

export function updateLayerAction(payload: UpdateLayer['payload']): UpdateLayer {
  return { type: CircuitActionTypes.UpdateLayer, payload };
}

export interface UpdateLayerGroup extends Action {
  type: CircuitActionTypes.UpdateLayerGroup;
  payload: {
    layerGroupId: string;
    name?: string;
    children?: string[];
  } & RoadEditorAction['payload'];
}

export function updateLayerGroupAction(payload: UpdateLayerGroup['payload']): UpdateLayerGroup {
  return { type: CircuitActionTypes.UpdateLayerGroup, payload };
}

export interface AddLayer extends Action {
  type: CircuitActionTypes.AddLayer;
  payload: { id: string } & Partial<LayerData> & RoadEditorAction['payload'];
}

export function addLayerAction(payload: AddLayer['payload']): AddLayer {
  return { type: CircuitActionTypes.AddLayer, payload };
}

export interface AddLayerGroup extends Action {
  type: CircuitActionTypes.AddLayerGroup;
  payload: { id: string } & Partial<LayerGroupData> & RoadEditorAction['payload'];
}

export function addLayerGroupAction(payload: AddLayerGroup['payload']): AddLayerGroup {
  return { type: CircuitActionTypes.AddLayerGroup, payload };
}

export interface DeleteLayer extends Action {
  type: CircuitActionTypes.DeleteLayer;
  payload: { layerId: string; visibility?: boolean; name: string } & RoadEditorAction['payload'];
}

export function deleteLayerAction(payload: DeleteLayer['payload']): DeleteLayer {
  return { type: CircuitActionTypes.DeleteLayer, payload };
}

export interface ChangeLayerOrder extends Action {
  type: CircuitActionTypes.ChangeLayersOrder;
  payload: {
    layerId: string;
    changeIndex: number;
  };
}

export function changeLayerOrderAction(payload: ChangeLayerOrder['payload']): ChangeLayerOrder {
  return { type: CircuitActionTypes.ChangeLayersOrder, payload };
}

export interface DeleteLayerGroup extends Action {
  type: CircuitActionTypes.DeleteLayerGroup;
  payload: { layerGroupId: string } & RoadEditorAction['payload'];
}

export function deleteLayerGroupAction(payload: DeleteLayerGroup['payload']): DeleteLayerGroup {
  return { type: CircuitActionTypes.DeleteLayerGroup, payload };
}

export interface ChangeDisplayStateLayerParameters extends Action {
  type: CircuitActionTypes.ChangeDisplayStateParameters;
  payload: {
    layerId?: string;
    newState: boolean;
    type?: 'layer' | 'layer-group';
  } & RoadEditorAction['payload'];
}

export function openLayerParametersAction(payload: {
  layerId: string;
  type: 'layer' | 'layer-group';
}): ChangeDisplayStateLayerParameters {
  return {
    type: CircuitActionTypes.ChangeDisplayStateParameters,
    payload: { layerId: payload.layerId, newState: true, type: payload.type || 'layer' },
  };
}

export function closeLayerParametersAction(): ChangeDisplayStateLayerParameters {
  return { type: CircuitActionTypes.ChangeDisplayStateParameters, payload: { newState: false } };
}

export interface CopyAllShapes extends Action {
  type: CircuitActionTypes.CopyAllShapes;
  payload: {
    toLayerId: string;
    shapes: CircuitShape[];
  } & RoadEditorAction['payload'];
}

export function copyAllShapesAction(payload: CopyAllShapes['payload']): CopyAllShapes {
  return { type: CircuitActionTypes.CopyAllShapes, payload };
}

export interface BringToFront extends Action {
  type: CircuitActionTypes.BringToFront;
  payload: {
    id: string;
    type: ShapeTypes;
  } & RoadEditorAction['payload'];
}

export function bringToFrontAction(payload: BringToFront['payload']): BringToFront {
  return { type: CircuitActionTypes.BringToFront, payload: { ...payload, userAction: true } };
}

export interface BringToBack extends Action {
  type: CircuitActionTypes.BringToBack;
  payload: {
    id: string;
    type: ShapeTypes;
  } & RoadEditorAction['payload'];
}

export function bringToBackAction(payload: BringToBack['payload']): BringToBack {
  return { type: CircuitActionTypes.BringToBack, payload: { ...payload, userAction: true } };
}

export interface UpdateSegmentsPortions extends Action {
  type: CircuitActionTypes.UpdateSegmentsPortions;
  payload: {
    segmentsIds: string[];
  };
}

export function updateSegmentsPortionsAction(payload: UpdateSegmentsPortions['payload']): UpdateSegmentsPortions {
  return { type: CircuitActionTypes.UpdateSegmentsPortions, payload };
}

export interface MoveSelection extends Action {
  type: CircuitActionTypes.MoveSelection;
  payload: {
    dx: number;
    dy: number;
  };
}

export function moveSelectionAction(payload: MoveSelection['payload']): MoveSelection {
  return { type: CircuitActionTypes.MoveSelection, payload };
}

export interface RotateSelection extends Action {
  type: CircuitActionTypes.RotateSelection;
  payload: {
    rotateValue: number;
  };
}

export function rotateSelectionAction(payload: RotateSelection['payload']): RotateSelection {
  return { type: CircuitActionTypes.RotateSelection, payload };
}

export function deleteShapeAction(payload: {
  shapeId: string;
  shapeType: ShapeTypes;
  userAction?: boolean;
}):
  | DeleteSegment
  | DeletePoint
  | DeleteStockZone
  | DeleteMeasurer
  | DeleteRack
  | DeleteTurn
  | DeleteDevice
  | DeleteNote {
  const id = payload.shapeId;
  const userAction = payload.userAction;

  const shapeType = payload.shapeType;
  switch (shapeType) {
    case ShapeTypes.SegmentShape: {
      return deleteSegmentAction({ id, userAction });
    }

    case ShapeTypes.PointShape: {
      return deletePointAction({ id, userAction });
    }

    case ShapeTypes.StockZoneShape: {
      return deleteStockZoneAction({ id, userAction });
    }

    case ShapeTypes.MeasurerShape: {
      return deleteMeasurerAction({ id, userAction });
    }

    case ShapeTypes.RackShape: {
      return deleteRackAction({ id, userAction });
    }

    case ShapeTypes.TurnShape: {
      return deleteTurnAction({ id, userAction });
    }

    case ShapeTypes.DeviceShape: {
      return deleteDeviceAction({ id, userAction });
    }

    case ShapeTypes.ZoneShape: {
      return deleteZoneAction({ id, userAction });
    }

    case ShapeTypes.NoteShape: {
      return deleteNoteAction({ id, userAction });
    }

    default: {
      assert<Equals<typeof shapeType, never>>();
    }
  }

  throw new Error(`Unknown shape type: ${payload.shapeType}`);
}

export interface DeleteMultipleShapes extends Action {
  type: CircuitActionTypes.DeleteMultipleShapes;
  payload: {
    actions: DeleteAction[];
    force?: boolean;
  };
}

export function deleteMultipleShapesAction(payload: DeleteMultipleShapes['payload']): DeleteMultipleShapes {
  return { type: CircuitActionTypes.DeleteMultipleShapes, payload };
}

export interface DeleteMultipleTurnsSuccess extends Action {
  type: CircuitActionTypes.DeleteMultipleTurnSuccess;
  payload: {
    ids: string[];
  };
}

export function deleteMultipleTurnsSuccessAction(
  payload: DeleteMultipleTurnsSuccess['payload']
): DeleteMultipleTurnsSuccess {
  return { type: CircuitActionTypes.DeleteMultipleTurnSuccess, payload };
}

export interface TransferToAnotherLayer extends Action {
  type: CircuitActionTypes.TransferToAnotherLayer;
  payload: {
    layerId: string;
    shapes: SelectedShapeData[];
  } & RoadEditorAction['payload'];
}

export function transferToAnotherLayerAction(payload: TransferToAnotherLayer['payload']): TransferToAnotherLayer {
  return { type: CircuitActionTypes.TransferToAnotherLayer, payload };
}

export interface AskUpdateRack extends Action {
  type: CircuitActionTypes.AskUpdateRack;
  payload: {
    id: string;
    type: 'save' | 'saveSuccess'; // the type of action that will be dispatched in the update
  };
}

export function askUpdateRackAction(payload: AskUpdateRack['payload']): AskUpdateRack {
  return { type: CircuitActionTypes.AskUpdateRack, payload };
}

export interface RemoveAllGabarits extends Action {
  type: CircuitActionTypes.RemoveAllGabarits;
  payload:
    | {
        /** The layer id of the shapes we want to update, update all shapes of the circuit if omitted */
        layerId?: string;
      }
    | undefined;
}

export function removeAllGabaritsAction(payload: RemoveAllGabarits['payload']): RemoveAllGabarits {
  return { type: CircuitActionTypes.RemoveAllGabarits, payload };
}

export interface ChangeShapeVisibility extends Action {
  type: CircuitActionTypes.ChangeShapeVisibility;
  payload: {
    /** id of the shape(s) to change the visibility */
    id: string | string[];
    /** will the shape(s) be hidden, optional, if omitted the shape visibility will be toggled */
    hidden?: boolean;
  };
}

export function changeShapeVisibilityAction(payload: ChangeShapeVisibility['payload']): ChangeShapeVisibility {
  return { type: CircuitActionTypes.ChangeShapeVisibility, payload };
}

export interface UpdateGabaritState extends Action {
  type: CircuitActionTypes.UpdateGabaritState;
  payload: {
    /** The layerId of the shapes we want to update, update all shapes of the circuit if omitted */
    layerId?: string;
    /** The new value of the layer to know if we want to display all the gabarit or not */
    newDisplayGabarit?: boolean;
  };
}

export function updateGabaritStateAction(payload: UpdateGabaritState['payload']): UpdateGabaritState {
  return { type: CircuitActionTypes.UpdateGabaritState, payload };
}

export interface EnableAllGabarits extends Action {
  type: CircuitActionTypes.EnableAllGabarits;
  payload: {
    /** The layerId of the shapes we want to update, update all shapes of the circuit if omitted */
    layerId?: string;
    /** The defaultModel for the layer */
    defaultModel: string;
    /** The defaultPattern for the layer */
    defaultPattern: string;
  };
}

export function enableAllGabaritsAction(payload: EnableAllGabarits['payload']): EnableAllGabarits {
  return { type: CircuitActionTypes.EnableAllGabarits, payload };
}

export interface AlignElement extends Action {
  type: CircuitActionTypes.AlignElement;
  payload: {
    alignAction: AlignAction;
    selectedShapes: (CircuitShape | undefined)[];
  };
}

export function alignElementAction(payload: AlignElement['payload']): AlignElement {
  return { type: CircuitActionTypes.AlignElement, payload };
}

export interface CleanUpExcessSegmentLengths extends Action {
  type: CircuitActionTypes.CleanUpExcessSegmentLengths;
  payload: {
    shapes: CircuitShape[];
  };
}

export function cleanUpExcessSegmentLengthsAction(
  payload: CleanUpExcessSegmentLengths['payload']
): CleanUpExcessSegmentLengths {
  return { type: CircuitActionTypes.CleanUpExcessSegmentLengths, payload };
}

export type CircuitActions =
  | SaveCircuitToHistory
  | ApplyUpdatedShapesFromYJS
  | LoadCircuitFromYJS
  | ImportCircuit
  | ImportCircuitSuccess
  | ImportCircuitFailure
  | DeleteSelectedShapes
  | DeleteSelectedShapesSuccess
  | SelectShape
  | SelectMultipleShapes
  | UnselectShape
  | ClearShapesSelection
  | AddZone
  | AddZoneFailure
  | AddStockZone
  | AddStockZoneFailure
  | AddRack
  | AddRackFailure
  | AddPoint
  | AddPointFailure
  | AddSegment
  | AddSegmentFailure
  | AddMeasurer
  | AddMeasurerFailure
  | UpdateLayer
  | UpdateLayerGroup
  | ImportLayers
  | AddLayer
  | AddLayerGroup
  | DeleteLayer
  | DeleteLayerGroup
  | ChangeDisplayStateLayerParameters
  | CopyAllShapes
  | SegmentMoved
  | MultipleSegmentsMoved
  | ChangeLayerOrder
  | BringToFront
  | BringToBack
  | PointMoved
  | UpdateMeasurer
  | DeleteAllCellTemplates
  | ImportCellTemplates
  | DeleteAllShapes
  | UpdateSegmentsPortions
  | AddMultipleSegments
  | MoveSelection
  | RotateSelection
  | UnselectSeveralShapes
  | TransferToAnotherLayer
  | AskUpdateRack
  | RemoveAllGabarits
  | ChangeShapeVisibility
  | DeleteMultipleShapes
  | DeleteMultipleTurnsSuccess
  | RenameShape
  | UpdateTurn
  | AddDevice
  | AddNote
  | AddDeviceFailure
  | AttachMeasurer
  | DetachMeasurer
  | UpdateGabaritState
  | EnableAllGabarits
  | AlignElement
  | CleanUpExcessSegmentLengths;
