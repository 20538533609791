import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { findShapeOrientation, getClosestPointInSegment, pDistance } from 'drawings/helpers';
import type { LoadedSegment } from 'reducers/circuit/state';
import type { Dictionary } from 'shared';
import { normalizeAngleToMinusPiPi, toRad } from 'utils/helpers';

export interface PositionItinerary {
  /** coordinate in x */
  x: number;
  /** coordinate in y */
  y: number;
  /** cap of the robot in radians in [-pi, pi] */
  heading?: number;
}

export interface Itinerary {
  /** id of the itinerary */
  id: string;
  /** from which point the itinerary has been computed */
  from?: PositionItinerary;
  /** to which point the itinerary has been computed */
  to?: PositionItinerary;
  /** the length of the itinerary in meters */
  distance: number;

  /** The cost of the itinerary (for internal SDK computation) */
  cost?: number;

  /** the duration of the itinerary in seconds */
  duration?: number;

  /** the list of the ids of the portions of the itinerary */
  portionIds?: number[];

  /** the list of the positions of the itinerary */
  positions: PositionItinerary[];

  /** the id of the error for the libTrack library (0 = OK) */
  errorId: number;
  /** the error message for the libTrack library corresponding for errorId */
  error: string;

  /**
   * The index of the robot at the origin of the itinerary (optional)
   */
  robotIndex?: number;

  /**
   * origin of the itinerary.
   * @type {string}
   */
  origin?: 'route' | 'simu';
}

export interface RoutesSliceState {
  /** the list of the itineraries */
  itineraries: Itinerary[];
  /** the layer group to use by default to compute the itineraries */
  selectedLayerGroup: string | null;
  /** the path flag to use by default to compute the itineraries */
  pathFlag: number;
  /** if the itineraries should be displayed or not outside of the tool */
  keepDisplayItineraryOnLeave: boolean;

  nextItineraryFrom?: PositionItinerary;
  nextItineraryTo?: PositionItinerary;

  nextClickSelect?: 'from' | 'to';
}

export const defaultPathFlag = 162;

const localStorageKeepDisplayOnLeaveKey = 'route-keepDisplayOnLeave';

const initialState: RoutesSliceState = {
  itineraries: [],
  selectedLayerGroup: null,
  pathFlag: defaultPathFlag,
  keepDisplayItineraryOnLeave: localStorage.getItem(localStorageKeepDisplayOnLeaveKey) === 'true',
};

const routesSlicePrivate = createSlice({
  initialState,
  name: 'routes',
  reducers: {
    addItinerary: (state, action: PayloadAction<Itinerary>) => {
      state.itineraries.push(action.payload);
    },
    mergeItinerary: (state, action: PayloadAction<Itinerary>) => {
      const itinerary = action.payload;
      const index = state.itineraries.findIndex((it) => it.id === itinerary.id);
      if (index === -1) {
        state.itineraries.push(itinerary);
      } else {
        state.itineraries[index] = {
          ...state.itineraries[index],
          distance: state.itineraries[index].distance + itinerary.distance,
          duration: (state.itineraries[index].duration ?? 0) + (itinerary.duration ?? 0),
          positions: [...state.itineraries[index].positions, ...itinerary.positions],
          portionIds: [...(state.itineraries[index].portionIds ?? []), ...(itinerary.portionIds ?? [])],
        };
      }
    },
    mergeItineraryAndOptimizePositions: (state, action: PayloadAction<Itinerary>) => {
      const itinerary = action.payload;
      const index = state.itineraries.findIndex((it) => it.id === itinerary.id);
      if (index === -1) {
        state.itineraries.push(itinerary);
      } else {
        const newPositionsUnfiltered = [...state.itineraries[index].positions, ...itinerary.positions];

        const positionsSet = new Set<string>();
        const newPositions = newPositionsUnfiltered.filter((pos) => {
          const key = `${pos.x.toFixed(3)},${pos.y.toFixed(3)}`;
          if (positionsSet.has(key)) {
            return false;
          }

          positionsSet.add(key);

          return true;
        });

        const newPortionIds = Array.from(
          new Set([...(state.itineraries[index].portionIds ?? []), ...(itinerary.portionIds ?? [])])
        );

        state.itineraries[index] = {
          ...state.itineraries[index],
          positions: newPositions,
          portionIds: newPortionIds,
        };
      }
    },
    addMultipleItineraries: (state, action: PayloadAction<Itinerary[]>) => {
      state.itineraries.push(...action.payload);
    },
    setItineraries: (
      state,
      action: PayloadAction<{ itineraries: Itinerary[]; previousOriginToKeep?: 'route' | 'simu' | undefined }>
    ) => {
      const { itineraries, previousOriginToKeep = undefined } = action.payload;

      if (previousOriginToKeep) {
        const filteredItineraries = state.itineraries.filter(
          (itinerary) => itinerary.origin === previousOriginToKeep && state.keepDisplayItineraryOnLeave
        );
        state.itineraries = [...filteredItineraries, ...itineraries];
      } else {
        state.itineraries = itineraries;
      }
    },
    removeItinerary: (state, action: PayloadAction<{ id: string }>) => {
      state.itineraries = state.itineraries.filter((itinerary) => itinerary.id !== action.payload.id);
    },
    clearAllItineraries: (state) => {
      state.itineraries = state.keepDisplayItineraryOnLeave
        ? state.itineraries.filter((itinerary) => itinerary.origin === 'route')
        : [];
    },
    selectLayerGroup: (state, action: PayloadAction<{ layerGroup: string }>) => {
      state.selectedLayerGroup = action.payload.layerGroup;
    },
    setNewPathFlag: (state, action: PayloadAction<{ pathFlag: number }>) => {
      state.pathFlag = action.payload.pathFlag;
    },
    setNextItineraryPosition: (
      state,
      action: PayloadAction<{
        from: PositionItinerary;
        to: PositionItinerary;
      }>
    ) => {
      if (action.payload.from) state.nextItineraryFrom = action.payload.from;
      if (action.payload.to) state.nextItineraryTo = action.payload.to;
    },
    resetItineraryPositions: (state) => {
      state.nextItineraryFrom = undefined;
      state.nextItineraryTo = undefined;
    },
    clearNextItineraryPosition: (state, action: PayloadAction<{ from?: boolean; to?: boolean }>) => {
      state.itineraries = [];
      if (action.payload.from) state.nextItineraryFrom = undefined;
      if (action.payload.to) state.nextItineraryTo = undefined;
    },
    setSelectNextPoint: (state, action: PayloadAction<{ select: 'from' | 'to' | undefined }>) => {
      state.nextClickSelect = action.payload.select;
    },
    selectClosestPoint: (
      state,
      action: PayloadAction<{
        x: number;
        y: number;
        segmentsIds: string[];
        segmentsEntities: Dictionary<LoadedSegment>;
      }>
    ) => {
      const x = action.payload.x;
      const y = action.payload.y;

      const segmentsIds = action.payload.segmentsIds;
      const segmentsEntities = action.payload.segmentsEntities;

      let minimumDistance = 1 / 0; // minimum distance
      let closestSegmentId: string | undefined = undefined;
      for (let i = 0, l = segmentsIds.length; i < l; i++) {
        const segment = segmentsEntities[segmentsIds[i]];
        const coords = segment.geometry.coordinates;

        const d = pDistance(x, -y, coords[0][0], coords[0][1], coords[1][0], coords[1][1]);
        if (d < minimumDistance) {
          minimumDistance = d;
          closestSegmentId = segment.id as string;
        }
      }

      if (!closestSegmentId) return;

      const closestSegment = segmentsEntities[closestSegmentId];
      const coords = closestSegment.geometry.coordinates;
      const [closestPoint] = getClosestPointInSegment([x, -y], coords[0], coords[1]);
      const heading = normalizeAngleToMinusPiPi(toRad(findShapeOrientation(coords[0], coords[1])));

      const nextPoint = {
        x: closestPoint[0],
        y: closestPoint[1],
        heading,
      };

      if (state.nextClickSelect === 'from') {
        state.nextItineraryFrom = nextPoint;
      } else if (state.nextClickSelect === 'to') {
        state.nextItineraryTo = nextPoint;
      }

      if (state.nextClickSelect === 'from') {
        state.nextClickSelect = 'to';
      } else if (state.nextClickSelect === 'to') {
        state.nextClickSelect = undefined;
      }

      state.itineraries = [];
    },
    restoreInitialRouteState: (state) => {
      state = initialState;
    },
    setKeepDisplayItineraryOnLeave: (state, action: PayloadAction<boolean>) => {
      state.keepDisplayItineraryOnLeave = action.payload;

      localStorage.setItem(localStorageKeepDisplayOnLeaveKey, action.payload.toString());
    },
    reverseRoute: (state) => {
      state.itineraries = [];
      [state.nextItineraryFrom, state.nextItineraryTo] = [state.nextItineraryTo, state.nextItineraryFrom];
    },
  },
});

export const routesSlice = {
  reducer: routesSlicePrivate.reducer,
  name: routesSlicePrivate.name,
};

export const {
  addItinerary,
  removeItinerary,
  clearAllItineraries,
  selectLayerGroup,
  setNewPathFlag,
  setNextItineraryPosition,
  resetItineraryPositions,
  clearNextItineraryPosition,
  setSelectNextPoint,
  addMultipleItineraries,
  selectClosestPoint,
  restoreInitialRouteState,
  setItineraries,
  mergeItineraryAndOptimizePositions,
  setKeepDisplayItineraryOnLeave,
  reverseRoute,
} = routesSlicePrivate.actions;
