import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { closeDialogAction } from 'actions';
import type { IntervalTrigger } from 'models/simulation';
import { useCallback, useMemo, useState } from 'react';
import { selectTrigger, setTrigger } from 'simulation/triggers';
import { useAppDispatch, useAppSelector } from 'store';
import { isIntervalTrigger } from '../flow-configuration/triggers/interval-triggers';
import { formatTriggerData, getTriggerTypeIcon } from './triggers-list';

export function UpdateIntervalThroughputDialog(): JSX.Element {
  const dispatch = useAppDispatch();

  const triggers = useAppSelector((state) => state.triggers.triggers);
  const intervalTriggers = useMemo(() => triggers.filter(isIntervalTrigger), [triggers]);

  const [intervalTriggersToUpdate, setIntervalTriggersToUpdate] = useState<IntervalTrigger[]>([]);
  const [percentageValue, setPercentageValue] = useState('1');

  const handleChangeAllIntervalTriggers = (triggerId: string | 'all', newValue: boolean): void => {
    let newIntervalTriggers = [...intervalTriggersToUpdate];

    if (triggerId === 'all') {
      if (newValue) {
        intervalTriggers.forEach((trigger) => {
          const isTriggerAlreadyAdded = !!intervalTriggersToUpdate.find(
            (triggerToUpdate) => triggerToUpdate.id === trigger.id
          );

          if (!isTriggerAlreadyAdded) {
            newIntervalTriggers.push(trigger);
          }
        });
      } else {
        newIntervalTriggers = newIntervalTriggers.slice(0, -newIntervalTriggers.length);
      }
    } else {
      if (newValue) {
        const triggerToUpdate = intervalTriggers.find((trigger) => trigger.id === triggerId);

        if (triggerToUpdate) {
          newIntervalTriggers.push(triggerToUpdate);
        }
      } else {
        newIntervalTriggers = newIntervalTriggers.filter((trigger) => trigger.id !== triggerId);
      }
    }

    setIntervalTriggersToUpdate(newIntervalTriggers);
  };

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleSave = (): void => {
    const newIntervalTrigger = [...intervalTriggersToUpdate];

    intervalTriggersToUpdate.forEach((trigger, i) => {
      const count = trigger.count ?? 1;
      const actualInterval = trigger.interval;

      const actualThroughput = Math.round((count * 3600) / actualInterval);

      const newThroughput = actualThroughput * (1 + Number(percentageValue) / 100);

      const newInterval = (count * 3600) / newThroughput;

      newIntervalTrigger[i] = {
        ...newIntervalTrigger[i],
        interval: newInterval,
      };
    });

    newIntervalTrigger.forEach((trigger) => {
      dispatch(setTrigger(trigger));
    });

    dispatch(selectTrigger(undefined));

    handleClose();
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0 }}>Update Interval Throughput</DialogTitle>
      <DialogContent sx={{ marginTop: 5 }}>
        <TriggersList
          intervalTriggers={intervalTriggers}
          intervalTriggersToUpdate={intervalTriggersToUpdate}
          handleChangeAllIntervalTriggers={handleChangeAllIntervalTriggers}
        />
      </DialogContent>
      <DialogContent sx={{ minHeight: '70px' }}>
        <Box component={'div'}>
          Increase or decrease the selected throughput by
          <FormControl sx={{ marginLeft: 2, marginRight: 2, width: '75px' }}>
            <Input
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              size="small"
              value={percentageValue}
              onChange={(e) => setPercentageValue(e.target.value)}
              type="number"
            ></Input>
          </FormControl>
          {`(x${(1 + Number(percentageValue) / 100).toFixed(2)})`}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" endIcon={<SaveIcon />} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface TriggersListProps {
  intervalTriggers: IntervalTrigger[];
  intervalTriggersToUpdate: IntervalTrigger[];
  handleChangeAllIntervalTriggers: (triggerId: string | 'all', newValue: boolean) => void;
}

function TriggersList({
  intervalTriggers,
  intervalTriggersToUpdate,
  handleChangeAllIntervalTriggers,
}: TriggersListProps): JSX.Element {
  const allTriggersSelected = useMemo(() => {
    return !!(intervalTriggersToUpdate.length && intervalTriggersToUpdate.length === intervalTriggers.length);
  }, [intervalTriggers, intervalTriggersToUpdate]);

  const someTriggersSelected = useMemo(() => {
    return !!(intervalTriggersToUpdate.length && intervalTriggersToUpdate.length < intervalTriggers.length);
  }, [intervalTriggers, intervalTriggersToUpdate]);

  return (
    <List dense>
      {intervalTriggers.length > 0 && (
        <ListItem
          key="all-triggers"
          secondaryAction={
            <Tooltip title="Select/deselect all triggers" disableInteractive placement="left">
              <Checkbox
                checked={allTriggersSelected}
                indeterminate={someTriggersSelected && !allTriggersSelected}
                onChange={(e, newValue) => {
                  handleChangeAllIntervalTriggers('all', newValue);
                }}
              />
            </Tooltip>
          }
        ></ListItem>
      )}
      {intervalTriggers.map((trigger) => {
        const triggerMainDataTxt = formatTriggerData(trigger);
        const triggerIcon = getTriggerTypeIcon(trigger);

        const isTriggerChecked = !!intervalTriggersToUpdate.find(
          (triggerToUpdate) => triggerToUpdate.id === trigger.id
        );

        return (
          <ListItem
            key={trigger.id}
            secondaryAction={
              <Tooltip title={`Select/deselect ${trigger.name}`} disableInteractive placement="left">
                <Checkbox
                  checked={isTriggerChecked}
                  onChange={(e, newValue) => {
                    handleChangeAllIntervalTriggers(trigger.id, newValue);
                  }}
                />
              </Tooltip>
            }
          >
            {triggerIcon && <ListItemIcon>{triggerIcon}</ListItemIcon>}
            <ListItemText
              primary={trigger.name}
              secondary={
                <>
                  {trigger.type} / {triggerMainDataTxt}
                </>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
