import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatTriggerData, getTriggerTypeIcon } from 'components/toolboxes/simulation/triggers-list';
import type { Flow } from 'flows/flows';
import type { IntervalTrigger } from 'models/simulation';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store';

interface DisplayTriggersCellProps {
  flow: Flow;
}
export function DisplayTriggersCell({ flow }: DisplayTriggersCellProps): JSX.Element {
  const triggers = useAppSelector((state) => state.triggers.triggers);

  const triggersOfFlow = useMemo(() => {
    return triggers.filter((trigger) =>
      Array.isArray(trigger['flowId']) ? trigger['flowId'].includes(flow.id) : trigger['flowId'] === flow.id
    );
  }, [flow.id, triggers]);

  const getCountOfIntervalTrigger = useCallback((trigger: IntervalTrigger): number => trigger.count ?? 1, []);

  const displayTriggers = useMemo(() => {
    if (triggersOfFlow.length > 0) {
      return (
        <>
          {triggersOfFlow.map((trigger, index) => (
            <Box component="div" sx={{ mb: 1 }} key={index}>
              <Stack alignItems="center" direction="row" gap={1}>
                {getTriggerTypeIcon(trigger)}
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {trigger.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'gray', textTransform: 'capitalize' }}>
                  - {trigger.type}
                </Typography>
              </Stack>
              {trigger.type === 'buffer' && trigger.size && (
                <Typography variant="body1" sx={{ color: 'gray' }}>
                  Size: {trigger.size}
                </Typography>
              )}
              {trigger.type === 'interval' && trigger.interval && (
                <Stack alignItems="center" direction="row" gap={1}>
                  <Typography variant="body1" sx={{ color: 'gray' }}>
                    {formatTriggerData(trigger)} {' ('}
                    {Math.round((getCountOfIntervalTrigger(trigger) * 3600) / trigger.interval)} t/hr{')'}
                  </Typography>
                </Stack>
              )}
            </Box>
          ))}
        </>
      );
    }

    return <Typography variant="body1">No triggers assigned</Typography>;
  }, [getCountOfIntervalTrigger, triggersOfFlow]);

  return displayTriggers;
}
