import { point as pointTurf } from '@turf/helpers';
import { centerOfMass, bbox as turfBbox } from '@turf/turf';
import type { EditorDrawing } from 'drawings/editor.drawing';
import type { BBox } from 'geojson';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import { CircuitService } from 'services/circuit.service';
import store from 'store';

export function zoomToRobot(robotId: number, skipAnim = false): void {
  const robots = store.getState().robots.robots;
  const robot = robots.find((robot) => robot.id === robotId);

  const isLibCirEnabled = store.getState().editor.isRender2Enabled;

  let drawing: EditorDrawing | undefined;

  if (!isLibCirEnabled) {
    drawing = CircuitService.getDrawingReference();
  }

  if (robot) {
    const robotPoint = pointTurf([robot.position.x * 100, robot.position.y * 100]);

    let bbox: BBox;
    try {
      bbox = turfBbox(robotPoint.geometry);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Computation of the BBox of the robot '${robotId} failed'`, robot, e);

      return;
    }

    const center = centerOfMass(robotPoint.geometry);
    const centerCoords = center.geometry.coordinates;

    // for points, we define a zoom to an arbitrarily value given that the bbox has a width and height of 0
    const scale = 1.5;

    if (!isLibCirEnabled) {
      drawing?.zoomTo(centerCoords[0], centerCoords[1], scale, skipAnim, true);
    } else {
      const halfWidth = (bbox[2] - bbox[0]) / 2;
      const halfHeight = (bbox[3] - bbox[1]) / 2;

      store.dispatch(
        setCameraPosition({
          position: {
            minX: (centerCoords[0] - halfWidth) / 100,
            maxX: (centerCoords[0] + halfWidth) / 100,
            minY: (centerCoords[1] - halfHeight) / 100,
            maxY: (centerCoords[1] + halfHeight) / 100,
          },
        })
      );
    }
  }
}
